<template>
  <div class="quoteview" ref="quoteviewref">
    <!-- error message if applicable -->
    <div v-show="!quoteLoaded && !searchResultsLoaded" class="message">
      {{ message }}
    </div>

    <!-- search results if any -->
    <SearchResults
      v-bind:searchTerm="ticker"
      v-bind:results="searchResults"
      v-if="searchResultsLoaded"
    />

    <!-- quote data -->
    <Quote
      v-bind:quote="quote"
      v-bind:rtQuote="rtQuote"
      v-bind:fundData="fundData"
      v-bind:chartData="chartData"
      v-bind:chartDataLoaded="quoteChartLoaded"
      v-if="quoteLoaded"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { useHead } from '@unhead/vue'
import config from "@/config.js";
import { fetchMixin } from "@/mixins/fetch.js";
import Quote from "@/components/Quote.vue";
import SearchResults from "@/components/SearchResults.vue";

export default {
  name: "QuoteView",
  components: {
    Quote,
    SearchResults,
  },
  mixins: [fetchMixin],
  props: {
    ticker: {
      type: String,
      required: true,
    },
  },
  // setup is similar to a lifecycle method and runs before anything else
  // but not before props are resolved (still have access to props)
  // we don't use "this" in setup
  // you return an object with values that you want available in the template
  // Variables aren't declared unless you use "ref" or "reactive"
  // Can have two arguments: props and context
  // https://blog.deepgram.com/diving-into-vue-3-setup-function/
  setup(props) {
    let symbol = props.ticker.toUpperCase();
    if (symbol.endsWith(".US")) {
      symbol = symbol.slice(0, -3);
    }
    const pageTitle = symbol + " Stock Quote & Analysis";
    const description  = "Get the latest quote, information and analysis for " + symbol + " from Stocknito.";
    useHead({
      title: pageTitle,
      meta: [
        { name: 'description', content: description },
      ],
    })
  },
  _metaInfo() { // Was this for vue-meta? TODO Should delete this at some point
    return {
      // https://vue-meta.nuxtjs.org/faq/component-props.html
      // title: "Stock Quote for " + this.ticker.toUpperCase() + " | Stocknito"
      // title: this.ticker.toUpperCase() + " Quote | Stocknito"
      title: this.pageTitle + " | Stocknito",

      // if (this.quoteLoaded) {
      //   title: this.ticker.toUpperCase() + " Quote | Stocknito"
      // } else {
      //   title: "Searching for " + this.ticker + " | Stocknito"
      // }
    };
  },
  data() {
    return {
      message: "Searching...",
      invalidTickerRegex: /[^.\w\d _-]/i,
      pageTitle: this.ticker.toUpperCase() + " Quote",
      quote: {},
      rtQuote: {},
      fundData: {},
      quoteLoaded: false,
      chartData: [],
      quoteChartLoaded: false,
      searchResults: [],
      searchResultsLoaded: false,
    };
  },
  // Links for view not refreshing:
  // https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
  // https://forum.vuejs.org/t/view-is-not-refreshing-when-params-id-is-changing/82848
  // https://forum.vuejs.org/t/how-to-reload-component-when-navigating-among-pages-rendered-by-a-same-component/112316/3
  /*watch: {
    $route: "loadQuote" // not sure why I had this from before
  },*/
  /*watch: {
    $route(to, from) { // react to route changes...
      if(to !== from){
        location.reload(); // problem with this is that it re-loaded the entire component (more network calls)
      }
    }
  },*/
  methods: {
    async getQuote() {
      // var ticker = this.$route.params.ticker; // creates a tight coupling with the route
      var ticker = this.ticker; // now route attribute is passed to component through a prop

      // Verify the ticker is valid
      if (this.invalidTickerRegex.test(ticker)) {
        this.message = "Sorry, " + ticker + " is not a valid symbol 👎";
        return;
      }
      this.message = "Searching for " + ticker + "... 🔍";

      // Get saved chart start date from web storage, if any
      var startDateQueryParam = "";
      let dquoteChartStartDate = localStorage.getItem("dquoteChartStartDate");
      if (dquoteChartStartDate) {
        startDateQueryParam = "?start-date=" + dquoteChartStartDate;
      }

      // Make the request
      var tickerPath =
        config.apiUrl +
        config.apiPathQuote +
        "/" +
        ticker +
        startDateQueryParam;
      let { data, statusCode, error } = await this.makeRequest(tickerPath);
      if (!data || statusCode != 200) {
        // console.log("status code: ", statusCode)
        if (statusCode == 400) {
          this.message = "No symbol specified 👎";
        } else if (statusCode == 404) {
          this.message = "No results found for " + ticker + " 👀";
        } else if (error.toLowerCase() == "network error") {
          // possibly a throttled 503 response?
          this.message =
            "You're either too fast or something else bad happened, try refreshing in a few seconds 🐌";
        } else {
          this.message =
            "Something bad happened getting results for " + ticker + " 🤷‍♂️";
        }
        return;
      }

      // Got search results
      if (data.search_results != null) {
        this.searchResults = data.search_results;
        this.searchResultsLoaded = true;
        return;
      }

      // Get the quote data
      this.quote = data;
      this.rtQuote = data.rt_quote;
      this.fundData = data.fundamentals;
      this.quoteLoaded = true;

      // Get chart data if it exists
      if (data.chart_data != null && data.chart_data.length > 0) {
        this.chartData = data.chart_data;
        this.quoteChartLoaded = true;
      }
      this.saveInRecentQuotes();
    },
    loadQuote() {
      this.quoteLoaded = false;
      this.quoteChartLoaded = false;
      this.searchResultsLoaded = false;
      this.getQuote();
    },
    saveInRecentQuotes() {
      // Get recent quote list from local storage
      let dquoteRecentQuotes = localStorage.getItem("dquoteRecentQuotes");
      let exactSymbol = this.quote.symbol + "." + this.quote.exchange_code;
      let recentQuotesList = [];
      if (dquoteRecentQuotes) {
        recentQuotesList = dquoteRecentQuotes.split(",");
      }
      // console.log("recent list before: ", recentQuotesList)
      recentQuotesList.unshift(exactSymbol); // add to beginnng of list

      // Remove from currentlist if it's already there
      for (let i = recentQuotesList.length; i >= 1; i--) {
        if (exactSymbol == recentQuotesList[i]) {
          recentQuotesList.splice(i, 1);
        }
      }
      // Max list size is 6
      if (recentQuotesList.length > 6) {
        recentQuotesList.pop();
      }

      // console.log("recent list after: ", recentQuotesList)
      dquoteRecentQuotes = recentQuotesList.join(",");
      localStorage.setItem("dquoteRecentQuotes", dquoteRecentQuotes); // save in local storage
    },
  },
  created() {
    // can make BE api calls here; have access to the reactive data properties
    // DOM has not been mounted yet, so you can manipulate that

    // Get and load the data
    this.loadQuote();
  },
  mounted() {
    // called after the DOM has been mounted/rendered
    // you can get DOM elements and manipulate them
    //this.$refs.quoteviewref.$el.focus()     // What is this for?
    // try and focus on an element different then the search box (so search box is not in focus after search)
    //this.$refs.quoteviewref.focus()      // doesn't work
    //document.getElementById("quoteviewref")?.focus()
  },
};
</script>

<style scoped lang="scss">
.message {
  font-size: 1.8rem;
  margin-left: 10px;
  margin-right: 10px;
}
.quoteview {
  // Centering with flex
  display: flex;
  justify-content: center;
  align-items: flex-start; // Default is stretch so content expand to take vertical space

  margin-top: var(--content-vert-top-margin);
  margin-bottom: var(--content-vert-bottom-margin);
  // hrz margins will be set in specific components
}
</style>
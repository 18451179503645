<template>
      <div>
        <div class="title">Dividends</div>

        <div class="info-content">

          <div class="info-item bd">
            <div class="item-header bd-red">Dividend</div>
            <div class="item-description bd-red">
              Dividend is the annual dollar amount the company pays to its shareholders, for each 
              share owned. It's a portion of the company earnings that rewards stock holders for 
              investing in the company. This is a trailing 12 months value, so dividends paid out 
              over the last 12 months are added up to get this value. Typically companies pay a 
              dividend every quarter, but sometimes once a year or every month.
              <br /><br />
              A dividend of zero is not all bad. Growth companies for example may want to use all 
              their earnings to re-invest back in the business. In this case investors should look 
              for capital appreciation (the stock price going up) to make up for lack of dividends.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Yield</div>
            <div class="item-description bd-red">
              Yield, or dividend yield, represents the dividend payment expressed as a percentage 
              of the stock price. It is calculated as 
              <span class="code-segment">dividend per share / stock price</span>. 
              For example, if the annual dividend payment is $2 and the current stock price 
              is $100, the dividend yield is 
              <span class="code-segment">2 / 100 = 0.02 = 2%</span>. Typically the higher the number 
              the better, as it indicates investors receive a larger payment for every dollar invested.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Payout Ratio</div>
            <div class="item-description bd-red">
              The payout ratio is how much of the companies earnings (or net income) are paid to 
              investors as dividends. It is calculated as 
              <span class="code-segment">earnings per share / dividend per share</span>. 
              A higher value indicates the company is paying more of it's earnings as dividends, 
              and leaving less to re-invest back in the business. A value greater than 100% is not 
              sustainable, as the company is paying more dividends than income it's bringing in.
            </div>
          </div>

          <div class="info-note">
            The Sector Yield shown is an average (mean) value.
            <br />
            Values not shown or displayed as a "-" may be due to insufficient or missing data.
          </div>

        </div>

      </div>
</template>

<script>
export default {
  name: "DividendsInfo"
};
</script>

<style scoped lang="scss">
  @import "@/css/info-dialog.scss";
</style>

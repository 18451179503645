<template>
  <div>
    <!-- navigation -->
    <div class="nav-container">
      <!-- needed this div so hamburger doesn't float bottom -->
      <Nav v-bind:showHomeLink="false" class="nav" />
    </div>

    <div class="home">
      <!-- logo -->
      <img
        class="img-logo"
        alt="Stocknito logo"
        src="../assets/stocknito_logo_600.png"
      />


      <!-- search box -->
      <div class="search">
        <SearchBoxAuto ref="searchbox" />
      </div>

      <!-- standard quotes -->
      <div class="standard-quotes">
        <router-link :to="`/quote/${q.id}`" v-for="q in miniQuotes" :key="q.id">
          <QuoteBox class="standard-quote" v-bind:data="q" />
        </router-link>
      </div>

      <!-- mini quotes type selection -->
      <div class="quote-selection-margin">
        <div class="quote-selection">
          <div
            v-bind:class="[
              qsbClass,
              standardQSBSelected ? qsbSelectedClass : qsbUnselectedClass,
            ]"
            @click="getMiniQuotes('standard')"
            v-show="standardQSBVisible"
          >
            Standard
          </div>
          <div
            v-bind:class="[
              qsbClass,
              activeQSBSelected ? qsbSelectedClass : qsbUnselectedClass,
            ]"
            @click="getMiniQuotes('active')"
            v-show="activeQSBVisible"
          >
            Active
          </div>
          <div
            v-bind:class="[
              qsbClass,
              trendingQSBSelected ? qsbSelectedClass : qsbUnselectedClass,
            ]"
            @click="getMiniQuotes('trending')"
            v-show="trendingQSBVisible"
          >
            Trending
          </div>
          <div
            v-bind:class="[
              qsbClass,
              recentQSBSelected ? qsbSelectedClass : qsbUnselectedClass,
            ]"
            @click="getMiniQuotes('recent')"
            v-show="recentQSBVisible"
          >
            Recent
          </div>
        </div>
      </div>

      <!-- news section -->
      <div class="center-layout">
        <div class="news-section">
          <div
            class="news-title-row"
            v-bind:class="[
              newsButtonClass,
              showNews ? newsButtonSelectedClass : newsButtonUnselectedClass,
            ]"
            @click="onClickNews()"
          >
            <div class="news-title">News</div>
          </div>
          <News class="news-component" v-if="showNews" v-bind:source="1" />
        </div>
      </div>

      <!-- about link -->
      <!-- <div class="about-link"><router-link to="/about" class="rl-about">About</router-link></div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useHead } from "@unhead/vue";
import config from "@/config.js";
import SearchBoxAuto from "@/components/SearchBoxAuto.vue";
import QuoteBox from "@/components/QuoteBox.vue";
import News from "@/components/News.vue";
import Nav from "@/components/Nav.vue";
import { fetchMixin } from "@/mixins/fetch.js";
import { utilsMixin } from "@/mixins/utils.js";

export default {
  name: "Home",
  components: {
    SearchBoxAuto,
    QuoteBox,
    News,
    Nav,
  },
  mixins: [
    fetchMixin,
    utilsMixin, // isMobile()
  ],
  setup() {
    useHead({
      meta: [
        {
          name: "keywords",
          content: `stocks, stock quotes, stock market, stock research, stock valuation, ` +
            `investing, investors, investment tools, financial information, personal finance, ` +
            `portfolio, finance, retirement, business, economy`,
        },
      ],
    })
  },
  data() {
    return {
      miniQuotes: [],
      recentQuotesExist: false, // is true if recent quotes found in local storage
      qsbClass: "qsb",
      qsbSelectedClass: "qsb-selected",
      qsbUnselectedClass: "qsb-unselected",
      standardQSBVisible: false, // are buttons visible?
      activeQSBVisible: false,
      trendingQSBVisible: false,
      recentQSBVisible: false,
      standardQSBSelected: false, // are buttons selected?
      activeQSBSelected: false,
      trendingQSBSelected: false,
      recentQSBSelected: false,
      showNews: false,
      newsButtonClass: "news-title-row",
      newsButtonSelectedClass: "news-title-row-selected",
      newsButtonUnselectedClass: "news-title-row-unselected",
    };
  },
  methods: {
    async getMiniQuotes(quotesType) {
      let pathParamsStr = "";
      let listTypeParamsStr = "";
      if (quotesType == "recent") {
        this.standardQSBSelected = false;
        this.activeQSBSelected = false;
        this.trendingQSBSelected = false;
        this.recentQSBSelected = true; // recent is selecetd
        pathParamsStr = this.getRecentQuotesParamsString();
      } else if (quotesType == "active") {
        this.standardQSBSelected = false;
        this.activeQSBSelected = true; // active is selecetd
        this.trendingQSBSelected = false;
        this.recentQSBSelected = false;
        listTypeParamsStr = "?type=active";
      } else if (quotesType == "trending") {
        this.standardQSBSelected = false;
        this.activeQSBSelected = false;
        this.trendingQSBSelected = true; // trending is selecetd
        this.recentQSBSelected = false;
        listTypeParamsStr = "?type=trending";
      }else {
        this.standardQSBSelected = true; // standard is selected
        this.activeQSBSelected = false;
        this.trendingQSBSelected = false;
        this.recentQSBSelected = false;
        quotesType = "standard"; // default to standard if it's not recent
      }

      // Make api call
      var miniPath = config.apiUrl + config.apiPathMiniQuote + pathParamsStr + listTypeParamsStr;
      // console.log("path: ", miniPath)
      let { data, statusCode } = await this.makeRequest(miniPath);
      if (!data || statusCode != 200) {
        return;
      }

      // If it's not standard quotes, limit the name length
      if (quotesType != "standard") {
        for (let i = 0; i < data.length; i++) {
          let name = data[i].name;
          if (name.length > 26) { // this was 18 and 16 before; how did we get these numbers?
            data[i].name = name.substring(0, 24) + "...";
          }
        }
      }

      this.miniQuotes = data;
      this.setQSBVisible(); // show buttons
      localStorage.setItem("dquoteHomeQuotesType", quotesType); // save in local storage
    },
    getRecentQuotesParamsString() {
      let dquoteRecentQuotes = localStorage.getItem("dquoteRecentQuotes");
      if (!dquoteRecentQuotes) {
        return "";
      }
      let recentQuotes = dquoteRecentQuotes.split(",");
      let params = new URLSearchParams();
      for (let i = 0; i < recentQuotes.length; i++) {
        if (!recentQuotes[i]) {
          continue;
        }
        params.append("q", recentQuotes[i]);
      }
      let paramsStr = params.toString();
      if (!paramsStr) {
        return "";
      }
      return "?" + paramsStr;
    },
    setQSBVisible() {
      if (this.miniQuotes) {
        this.standardQSBVisible = true;
        this.activeQSBVisible = true;
        this.trendingQSBVisible = true;
        if (this.recentQuotesExist) {
          this.recentQSBVisible = true;
        }
      } else {
        this.standardQSBVisible = false; // data not loaded yet
        this.activeQSBVisible = false;
        this.trendingQSBVisible = false;
        this.recentQSBVisible = false;
      }
    },
    onClickNews() {
      this.showNews = !this.showNews;
      // save in local storage; fyi bool stored is stored as a string
      localStorage.setItem("dquoteShowNews", this.showNews);
      //if (this.showNews) { // testing with scrolling to the news
      //  document.getElementById('middle').scrollIntoView();
      //}
    },
  },
  created() {
    // can make BE api calls here; have access to the reactive data properties
    // DOM has not been mounted yet, so you can manipulate that

    let quotesType = "standard";
    let dquoteRecentQuotes = localStorage.getItem("dquoteRecentQuotes");

    if (dquoteRecentQuotes && dquoteRecentQuotes.length > 0) {
      this.recentQuotesExist = true; // to show selection buttons or not
      // Get last clicked quotes type from local storage
      quotesType = localStorage.getItem("dquoteHomeQuotesType");
      if (quotesType == "recent") {
        this.standardQSBSelected = false;
        this.recentQSBSelected = true; // recent is selected
      } else {
        // standard
        this.standardQSBSelected = true; // standard is selected
        this.recentQSBSelected = false;
      }
    } else {
      this.recentQuotesExist = false;
    }
    this.getMiniQuotes(quotesType);

    // Show the news?
    let dquoteShowNews = localStorage.getItem("dquoteShowNews");
    if (dquoteShowNews) {
      let dquoteShowNewsBool = dquoteShowNews === "true"; // convert string to bool
      this.showNews = dquoteShowNewsBool;
    }
  },
  mounted() {
    if (!this.isMobile()) {
      this.$refs.searchbox.$refs.searchinput.focus();
    }
  },
};
</script>


<style scoped lang="scss">
.home {
  position: relative;
  padding-top: 90px;
}

.about-link {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  // margin-left: auto;
  // margin-right: 0;
  // text-align: right;
  // vertical-align: bottom;
}

.rl-about {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--font-color-graymid);
}

.img-logo {
  display: block;
  margin: 0 auto; // to center it?
  width: 300px;
}

.search {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.standard-quotes {
  max-width: var(--content-width);
  display: grid;
  justify-items: center; // align items in the center of their cell (horz)
  justify-content: center;
  // more info: https://www.digitalocean.com/community/tutorials/css-align-justify
  grid-template-columns: repeat(auto-fill, 19rem);
  // grid-template-columns: repeat(3, 1fr);
  // grid-template-columns: repeat(auto-fill, minmax(120px, 180px));
  // grid-auto-columns: minmax(200px, 600px);
  row-gap: 2.5rem;
  margin: 9rem auto 0rem auto; // was 9rem below
}

.standard-quote {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  // fade in one by one:
  // https://stackoverflow.com/questions/37109870/fade-in-each-li-one-by-one
  // https://stackoverflow.com/questions/39458334/make-div-appear-intermittently-using-animation-css-property-not-working
}

.quote-selection-margin {
  margin: 0 10px 0 10px;
}

.quote-selection {
  display: flex;
  justify-content: flex-end; //center; //flex-start;
  column-gap: 10px;
  max-width: calc(var(--content-width) - 200px);
  margin: 2.5rem auto 5rem auto; // was 9rem bottom, befor news
}

.qsb {
  padding: 7px 11px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  font-family: var(--font-roboto);
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--font-color-closeblack);
}

.qsb-selected {
  background-color: var(--selection-box-lightpurple);
}

.qsb-unselected {
  background-color: var(--layout-color-almostwhite);
  cursor: pointer;
}

.nav-container {
  display: flex;
  justify-content: flex-end;
}

.nav {
  // right align within div (using flex instead)
  // margin-left: auto; // this doesn't work witin in div
  // margin-right: 0;
  // float: right;
  margin-top: 10px;
  margin-right: 10px;
}

///////////////////// Start of News
.center-layout {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.news-section {
  width: 100%;
  max-width: var(--content-width);
  margin-bottom: 3em;
  margin-left: var(--content-min-horz-margin);
  margin-right: var(--content-min-horz-margin);
}
.news-title-row {
  display: inline-flex;
  flex-direction: row;
  gap: 0.3em;
  align-items: baseline;
  margin-bottom: 1em;
  cursor: pointer;

  padding: 8px 25px;
  border-radius: 20px;
  -moz-border-radius: 20px;

  font-size: 2rem;
  font-family: var(--font-roboto);
  font-weight: 700;
  color: var(--font-color-closeblack);
}
.news-title-row-selected {
  background-color: var(--selection-box-lightpurple);
}

.news-title-row-unselected {
  background-color: var(--layout-color-almostwhite);
}
.news-title {
}
.news-component {
  margin-bottom: 5em; // when news is expanded, margin between last news and About link
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 0.8rem solid transparent;
  border-right: 0.8rem solid transparent;
  border-top: 0.8rem solid var(--font-color-neutralblue);
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-left: 0.8rem solid var(--font-color-neutralblue);
}

// When the mini quotes go to 1 column, make the quote selection centered
@media (max-width: 359px) {
  .quote-selection {
    justify-content: center;
  }
}

// At phone width, reduce margins on left and right
@media (max-width: 400px) {
  .news-section {
    margin-left: var(--content-min-phone-horz-margin);
    margin-right: var(--content-min-phone-horz-margin);
  }
  .img-logo {
    width: 260px;
  }
}

@media (max-width: 540px) {
  .home {
    padding-top: 50px; // space above logo
  }
  .search {
    margin-top: 80px; // space between search and logo
  }
  .standard-quotes {
    grid-template-columns: repeat(
      auto-fill,
      18rem
    ); // decrease space between boxes
    row-gap: 2rem; // decrease the vert gap between boxes
    margin-top: 8rem; // space above default quotes; was 8
  }
}

// When the mini quotes go to 2 columns, move the quote selection as well
@media (max-width: 570px) {
  .quote-selection {
    max-width: calc(var(--content-width) - 350px);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
<template>
  <Line id="theLineChart" :data="chartData" :options="chartOptions" :plugins="plugins" />
</template>

<script>
import { Line } from "vue-chartjs";
import "chartjs-adapter-date-fns"; // I think this is to support time series
import { useIsMobile } from "@/composables/utils.js";

const _MS_PER_DAY = 1000 * 60 * 60 * 24; // milliseconds per day

export default {
  name: "QuoteLineChart",
  components: { Line },
  props: ["data"],
  setup(props) {
    const lineBorderBlue = "#50B0F5";
    const lineBorderGreen = "rgba(63, 204, 111, 1.0)"; //"#3FCC6F";
    const lineBorderRed = "rgba(250, 110, 112, 1.0)"; //"#FA6E70";
    const gradientGreen = "rgba(63, 204, 111, 0.7)";
    const gradientRed = "rgba(250,110, 112, 0.7)";
    const gradientBlue = "rgba(80, 176, 245, 0.7)";

    let chartLabels = [];
    let chartValues = [];
    let radiusValues = [];
    let lineBorderColor = lineBorderBlue;
    let gradientColor = gradientBlue;

    // Add the labels and closing prices to the chart data, if they exist
    if (props.data) {
      // var dataLength = props.data.length;   // vue/no-setup-props-destructure error
      for (let i = 0; i < props.data.length; i++) {
        var theDate = new Date(props.data[i].date);
        chartLabels.push(theDate);
        chartValues.push(props.data[i].close);
        radiusValues.push(0);
      }
      radiusValues[0] = 4; // https://stackoverflow.com/a/55169811

      // Change color based on up or down
      if (props.data.length >= 2) {
        // var firstVal = props.data[0].close;
        // var lastVal = props.data[dataLength - 1].close;
        if (props.data[0].close > props.data[props.data.length - 1].close) {
          // gone up
          lineBorderColor = lineBorderGreen;
          gradientColor = gradientGreen;
        } else if (
          props.data[0].close < props.data[props.data.length - 1].close
        ) {
          // gone down
          lineBorderColor = lineBorderRed;
          gradientColor = gradientRed;
        } else {
          // no change
        }
      } else {
        // < 2 chart values
      }
    }

    // Figure out time unit and step size
    let timeUnit = "month"
    let stepSize = 3
    if (chartLabels.length >= 2) {
      // These used in the manual calculation
      let yearDiff = chartLabels[0].getUTCFullYear() - chartLabels[chartLabels.length - 1].getUTCFullYear()
      // let monthDiff = Math.abs(chartLabels[0].getUTCMonth() - chartLabels[chartLabels.length - 1].getUTCMonth())
      // let dayDiff = Math.abs(chartLabels[0].getUTCDate() - chartLabels[chartLabels.length - 1].getUTCDate())

      // For dynamic calculation based on number of days
      let diffDays = Math.round((chartLabels[0] - chartLabels[chartLabels.length - 1])/ _MS_PER_DAY, 0)
      let dynamicCalcSteps = Math.ceil(diffDays * 0.006) // good for months
      // console.log("yearDiff: ", yearDiff, "monthDiff: ", monthDiff, ", diffDays: ", diffDays, ", calcSteps: ", dynamicCalcSteps)

      stepSize = dynamicCalcSteps // set the step size, when unit is months
      if (yearDiff >= 10) {
        // stepSize = 14          // these are the manual ones, which we won't use for now
      } else if (yearDiff >= 5) {
        // stepSize = 12
      } else if (yearDiff >= 2) {
        // stepSize = 6
      } else if (yearDiff <= 1 && diffDays <= 10) {
        timeUnit = "day"
        stepSize = 2
      } else if (yearDiff <= 1 && diffDays <= 31) {
        timeUnit = "day"
        stepSize = 5
      } else if (yearDiff <= 1 && diffDays <= 190) {
        stepSize = 1
      }
    }

    // If mobile, make the chart taller and do some extra stuff
    let chartAspectRatio = 2.4;
    let chartLineWidth = 1.8
    let dateTicksAlign = "center"
    if (useIsMobile()) {
      chartAspectRatio = 1.85;
      chartLineWidth = 1.2;  // decrease line width
      radiusValues[0] = 3;   // make the last dot on the line a little smaller
      dateTicksAlign = "end" // align dates on left side of tick
      if (timeUnit == "month" && stepSize == 14) { // 10yr
        stepSize = 24
      } else if (timeUnit == "month" && stepSize == 12) { // 5yr
        stepSize = 14
      } else if (timeUnit == "month" && stepSize == 3) { // 1yr
        stepSize = 3
      } else {
        stepSize = Math.ceil(stepSize * 1.5)
      }
    }
    // console.log("final step size: ", stepSize, "time unit: ", timeUnit)

    let prevChartHeight = 0;
    let prevChartWidth = 0;

    const chartData = {
      labels: chartLabels,
      datasets: [
        {
          data: chartValues,
          fill: true,
          pointRadius: radiusValues,
          pointBackgroundColor: lineBorderColor,
          pointBorderWidth: 0, // https://www.chartjs.org/docs/latest/charts/line.html#point-styling
          pointHoverRadius: 0,
          tension: 0.15,
          borderWidth: chartLineWidth,
          borderColor: lineBorderColor,
          backgroundColor: function (context) {
            // https://www.chartjs.org/docs/3.7.0/samples/advanced/linear-gradient.html
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              // This case happens on initial chart load
              return;
            }
            if (
              prevChartHeight == chartArea.height &&
              prevChartWidth == chartArea.width
            ) {
              // Don't run if the chart size didn't change
              return;
            }
            prevChartHeight = chartArea.height;
            prevChartWidth = chartArea.width;

            let gradient = ctx.createLinearGradient(
              0,
              0,
              0,
              chartArea.height * 1.7
            );
            gradient.addColorStop(0, gradientColor);
            // white color at the bottom
            gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.7)");
            return gradient;
          },
          // xAxisID: "xAxis", // it's xAxis by default I think
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      // maintainAspectRatio: false,
      aspectRatio: chartAspectRatio,
      resizeDelay: 100,
      scales: {
        x: {
          // min: "2019-06-19 00:00:00", // do these work? should remove at some point
          // suggestedMin: "2019-09-01 00:00:00",
          type: "time",
          grid: {
            display: true,
            drawOnChartArea: false,
            drawTicks: true,
          },
          time: {
            unit: timeUnit, // day // TODO set this and stepSize below based on data
            displayFormats: {
              month: "MMM yyyy", // TODO should this vary based on data? What about for day?
            },
            tooltipFormat: "MMM dd, yyyy",
          },
          ticks: {
            align: dateTicksAlign,
            stepSize: stepSize,
            // maxTicksLimit: 3,
            // source: "auto", // not sure if this works
            // min: "2019-07-01 00:00:00", // don't think this is supported
          }
        },
        y: {
          //type: "logarithmic", // https://www.chartjs.org/docs/latest/axes/cartesian/logarithmic.html
          //type: "linear",
          //bounds: "data", // ticks
          //grace: '10%',
          //offset: true,
          //labelOffset: 100,
          grid: {
            display: true,
            color: "#EDEDED",
          },
          border: {
            display: false,
          },
          ticks: { // if greater then 1000, abbrevaite numbers on y axis
            // All this and afterBuildTicks below is to try and support type=logarithmic
            // The numbers and lines get squished in some areas
            //align: "inner",
            //crossAlign: "near",
            //padding: 15,
            //stepSize: 200,
            //maxTicksLimit: 15,
            //autoSkip: false,
            //autoSkipPadding: 10,
            //min: 80,
            //max: 120,
            //suggestedMin: 20,
            //suggestedMax: 6000,
            //beginAtZero: true,
            callback: function (value) {
              var ranges = [
                { divider: 1e12, suffix: "T" },
                { divider: 1e9, suffix: "B" },
                { divider: 1e6, suffix: "M" },
                { divider: 1e3, suffix: "k" },
              ];
              function formatNumber(n) {
                for (var i = 0; i < ranges.length; i++) {
                  if (n >= ranges[i].divider) {
                    return (
                      (n / ranges[i].divider).toString() + ranges[i].suffix
                    );
                  }
                }
                // Trying to fix the issue where xsb.to showed 25.200000000000003
                function countDecimals(value) {
                  if ((value % 1) != 0)
                      return value.toString().split(".")[1].length;
                  return 0;
                }
                if (countDecimals(n) > 0) {
                  // console.log("truncating to", n.toFixed(2))
                  return n.toFixed(2);
                }
                // No change for value
                return n;
              }
              return formatNumber(value);
            },
          },
          // https://stackoverflow.com/questions/40183188/chart-js-not-allowing-y-axis-steps-with-logarithmic-scale
          // afterBuildTicks: function(chartObj) { //Build ticks labelling as per your need; doesn't seem to work
          //   chartObj.ticks = [];
          //   chartObj.ticks.push(80.0);
          //   chartObj.ticks.push(100.0);
          //   chartObj.ticks.push(120.0);
          // }
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          // https://www.chartjs.org/docs/latest/configuration/tooltip.html
          backgroundColor: "rgba(250, 250, 250, 0.7)",
          titleColor: "#000",
          titleFont: { weight: "normal" },
          bodyColor: "#000",
          bodyFont: { weight: "bold" },
          padding: 10
          // position: "nearest"  // where the tooltip arrow goes
          // enabled: true,
          // mode: "index", // looks like don't need to set this if setting interaction
          // intersect: false,
          // callbacks: {
          //     afterTitle: (context) => {
          //         console.log(context[0].raw)
          //     }
          // }
        },
      },
      interaction: {
        // this seems to do both tooltip and hover
        mode: "index",
        intersect: false,
      },
      animation: {
        duration: 1000, // TODO make it configurable
        // easing: 'easeInCubic'
      },
    };

    // https://stackoverflow.com/questions/68058199/chartjs-need-help-on-drawing-a-vertical-line-when-hovering-cursor
    const plugins = [
      {
        // This is for the hover line
        afterDraw: (chart) => {
          if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let y = chart.tooltip._active[0].element.y;
            let xAxis = chart.scales.x;
            let yAxis = chart.scales.y;
            let ctx = chart.ctx;
            ctx.save();
            // Draw line vertical
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "rgba(0, 0, 0, 0.3)";
            ctx.stroke();
            // Draw line horizontal
            ctx.beginPath();
            ctx.moveTo(xAxis.left, y);
            ctx.lineTo(xAxis.right, y);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
            ctx.stroke();
            // Draw a point; https://stackoverflow.com/questions/7812514/drawing-a-dot-on-html5-canvas
            ctx.beginPath();
            ctx.fillStyle = "rgba(0, 0, 0, 0.3)"; // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/fillStyle
            ctx.arc(x, y, 4, 0, Math.PI * 2, true);
            ctx.fill(); // seems to implicitly call closePath()
            ctx.restore();
          }
        },
      },
    ];

    return {
      chartOptions: chartOptions,
      chartData: chartData,
      plugins: plugins,
    };
  },
  methods: {
  }
};
</script>
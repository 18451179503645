<template>
      <div>
        <div class="title">Company</div>

        <div class="info-content">

          <div class="info-item bd">
            <div class="item-header bd-red">Market Cap</div>
            <div class="item-description bd-red">
              Market capitalization represents the market value of a company, based on the share 
              price and number of shares outstanding. It would be the dollar amount needed to buy 
              the entire company at the current price. It is calculated as 
              <span class="code-segment">total shares outstanding x share price</span>. 
              Company's with larger market cap are typically considered 
              safer investments as they are usually more established businesses with longer history.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Sector</div>
            <div class="item-description bd-red">
              Company's with similar business characteristics are grouped together into a sector. 
              There are about 11 different sectors and each represents a large segment of the economy. 
              Examples include Technology (Apple, Microsoft) and Energy (Chevron, Exxon Mobil). 
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Industry</div>
            <div class="item-description bd-red">
              While a sector represents a broad classification of the economy, industry is a narrow 
              classification within a sector. An industry is a specific group of similar types of 
              companies. For example, even though Apple and Microsoft are in the same Technology sector, 
              they are in different industries, Consumer Electronics and Software - Infrastructure.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Exchange</div>
            <div class="item-description bd-red">
              A stock exchange is a marketplace where financial securities (such as stocks) are 
              bought and sold. An exchange doesn't own any shares of stocks, it simply connects 
              buyers with sellers. Even though many exchanges have a physical location, the majority 
              of trading is done electronically. In the US the largest exchanges are the New York 
              Stock Exchange (NYSE) and the tech-focused Nasdaq. In Canada the largest exchange is 
              the Toronto Stock Exchange (TSX).
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Initial Public Offering (IPO) Date</div>
            <div class="item-description bd-red">
              The initial public offering (IPO) date is the date when the company issued shares to 
              public investors for the first time. It represents when a privately held company goes 
              “public” and regular investors can buy and sell shares. Investing in a new company 
              with a recent IPO date may be more risky as there is less historical financial data 
              available to determine a valuation.
            </div>
          </div>

          <div class="info-note">
            Values not shown or displayed as a "-" may be due to insufficient or missing data.
          </div>

        </div>

      </div>
</template>

<script>
export default {
  name: "CompanyInfo"
};
</script>

<style scoped lang="scss">
  @import "@/css/info-dialog.scss";
</style>

//import Vue from "vue";
// import VueRouter from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
// import VueMeta from "vue-meta"
import Home from "../views/Home.vue";
import QuoteView from "../views/QuoteView.vue";
import PageNotFound from "../views/PageNotFound.vue";

//Vue.use(VueRouter);

// https://github.com/nuxt/vue-meta
//Vue.use(VueMeta)  // TODO do we need this for vue3?

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    path: "/about",
    name: "About",
    // meta: {
    //   title: "About | Stocknito",
    //   metaTags: [
    //     {
    //       name: "description",
    //       content: "About Stocknito"
    //     }
    //   ]
    // },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    // meta: {
    //   title: "Contact | Stocknito"
    // },
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
  },
  {
    path: "/disclaimer",
    name: "Disclaimer",
    // meta: {
    //   title: "Disclaimer | Stocknito"
    // },
    component: () =>
      import(/* webpackChunkName: "disclaimer" */ "../views/Disclaimer.vue")
  },
  {
    path: "/x-info",
    name: "Info",
    component: () =>
      import(/* webpackChunkName: "info" */ "../views/Info.vue")
  },
  {
    path: "/sp500/pe",
    name: "SP500PE",
    component: () =>
      import(/* webpackChunkName: "sp500" */ "../testviews/SP500pe.vue")
  },
  {
    path: "/testchart",
    name: "TestChart",
    component: () =>
      import(/* webpackChunkName: "testchart" */ "../testviews/TestChart.vue")
  },
  {
    path: "/fonttest",
    name: "FontTest",
    component: () =>
      import(/* webpackChunkName: "testchart" */ "../testviews/FontTest.vue")
  },
  {
    path: "/news/:source",
    name: "NewsView",
    props: true,
    component: () =>
      import(/* webpackChunkName: "news" */ "../testviews/NewsView.vue")
  },
  {
    path: "/quote/:ticker",
    name: "QuoteView",
    component: QuoteView,
    props: true
  },
  {
    path: "/quoteorg/:ticker",
    name: "QuoteViewOrg",
    props: true,
    component: () =>
      import(/* webpackChunkName: "quotevieworg" */ "../views/QuoteViewOrg.vue")
  },
  {
    path: "/investing/stock-investing-checklist",
    name: "BuyStockChecklist",
    // meta: {
    //   title: "Stock Investing Checklist | Stocknito",
    //   metaTags: [
    //     {
    //       name: "description",
    //       content: "About Stocknito"
    //     }
    //   ]
    // },
    component: () =>
      import(/* webpackChunkName: "buystockchecklist" */ "../content/BuyStockChecklist.vue")
  },
  {
    path: '/:catchAll(.*)*',
    component: PageNotFound,
    // meta: {
    //   title: "404 Page Not Found | Stocknito"
    // }
  }
  // TODO fix this for vue3
  // {
  //   // in vue3, it's /:catchAll(.*)
  //   // navigation gaurd https://stackoverflow.com/questions/38376648/in-vue-and-vue-router-spa-showing-404-for-a-not-found-resource
  //   path: "*",
  //   name: "PageNotFound",
  //   component: PageNotFound,
  //   meta: {
  //     //hideHeader: true,
  //     //hideFooter: true,
  //     title: "404 | Stocknito"
  //   }
  // },
  // { path: '/:pathMatch(.*)', component: PageNotFound }
];

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes
// });

const router = createRouter({
  // Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(process.env.BASE_URL), // new property and it is mandatory
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // return to savedPosition when user clicks back button
      return savedPosition
    } else {
      // scroll to top
      return { top: 0 }
    }
  },
})

// For static and dynamic titles:
// https://stackoverflow.com/questions/66269837/vue-router-how-to-dynamically-generate-page-titles-for-dynamic-routes
// to handle meta tags: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// June 7/23 - This was the old code to change the page title; now using @unhead/vue
// const default_title = "Stocknito";
// // For quote view
// router.beforeEach((to, from, next) => {
//   if (to.params.ticker) {
//     to.meta.title = to.params.ticker + " Quote | " + default_title  // TODO it shows the exchange like aapl.us
//   }
//   next();
// });
// // For static meta (I guess this isn't executed if above is)
// router.afterEach((to) => {
//   document.title = to.meta && to.meta.title || default_title;
// });

// TODO do we need this for vue3?
// Delete this at some point?
// const default_title = "Stocknito";
// router.afterEach((to) => {
//     // Use next tick to handle router history correctly
//     // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//     // vue3: https://v3.vuejs.org/guide/migration/global-api-treeshaking.html#_2-x-syntax
//     Vue.nextTick(() => {
//         document.title = to.meta && to.meta.title || default_title;
//     });
// });

export default router;

<template>
  <!-- <svg viewBox="0 0 512 512" v-bind:style="{ fill: fillColor }">
    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
  </svg> -->

  <!-- <svg style="width: 1.7rem; height: 1.7rem; vertical-align: middle; fill: fillColor; overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"> -->

  <svg v-bind:style="{ fill: fillColor }" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path d="M512 469.333333a42.666667 42.666667 0 0 0-42.666667 42.666667v170.666667a42.666667 42.666667 0 0 0 85.333334 0v-170.666667a42.666667 42.666667 0 0 0-42.666667-42.666667z m16.213333-167.253333a42.666667 42.666667 0 0 0-32.426666 0 42.666667 42.666667 0 0 0-14.08 8.96 49.066667 49.066667 0 0 0-8.96 14.08A35.84 35.84 0 0 0 469.333333 341.333333a42.666667 42.666667 0 0 0 12.373334 30.293334 49.066667 49.066667 0 0 0 14.08 8.96A42.666667 42.666667 0 0 0 554.666667 341.333333a44.8 44.8 0 0 0-12.373334-30.293333 42.666667 42.666667 0 0 0-14.08-8.96zM512 85.333333a426.666667 426.666667 0 1 0 426.666667 426.666667A426.666667 426.666667 0 0 0 512 85.333333z m0 768a341.333333 341.333333 0 1 1 341.333333-341.333333 341.333333 341.333333 0 0 1-341.333333 341.333333z"  />
  </svg>
</template>

<script>
export default {
  name: "InfoCircle",
  props: {
    fillColor: {
      type: String,
      default: "#161616", // --font-color-almostblack
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  display: block;
  margin: auto;
  width: 2.1rem;
  height: 2.1rem;
}
</style>
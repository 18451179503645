// Vue commands
// vue create <name>
// npm run serve - to start project
// npm run build - for production
// vue ui

import { createApp } from "vue";
import { createHead } from "@unhead/vue" // Universal document <head> tag manager
import VueGtag from "vue-gtag";
import App from "./App.vue";
import router from "./router";
import config from "@/config.js";
// import store from "./store";

// What does this do??
// Vue.config.productionTip = false; // removed in vue3

// Global mixin; https://vuejs.org/v2/guide/mixins.html
/*Vue.mixin({
  data() {
    return {}
  },
  methods: {
    globalTestFunc() {}
  }
})*/

/* vue2 need to remove
new Vue({
  router,
  store,
  render: h => h(App) // renders root app component
}).$mount("#app"); // mount our new Vue app to the DOM at app id
*/

// https://unhead.harlanzw.com/integrations/vue/setup
// useful video that showed basics: https://www.youtube.com/watch?v=qzkdP9z5DLY
const head = createHead()

const app = createApp(App).use(head).use(router);
// app.use(store); // need to fix store/index.js
// app.use(router);

// Google Analytics
app.use(VueGtag, {
  config: { id: config.gtagMeasurementId }
}, router);

// Global mixin; https://vuejs.org/v2/guide/mixins.html
// Commented out for now as it's not really used
/*app.mixin({
  data() {
    return {}
  },
  methods: {
    globalTestFunc() {}
  }
})*/

// actually mount to DOM
app.mount('#app')

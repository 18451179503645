<template>
  <div class="quote-detail-box bd" :class="boxShadoww">
    <div class="box-name-row">
      <div class="box-name bd">{{ data.name }}</div>
      <!-- <div class="info-button"><InfoCircle :fillColor="'#7FC4FF'" #bfe2ff c4e4ff add9ff @click="openInfoDialog('earnings')" v-if="data.name == 'Earnings'" /></div> -->
      <div class="info-button" @click="openInfoDialog(data.name)"><InfoCircle :fillColor="'#bfe2ff'" /></div>
    </div>

    <!-- box-content #4BACFF #DFDFDF -->
    <div class="box-content bd" v-if="data.name != 'Company'">
      <template v-for="(detail, index) in data.details" :key="index">

        <!-- regular detail -->
        <div class="detail" v-if="detail.type == 'detail'">
          <div class="detail-main-row">
            <div class="detail-name">{{ detail.name.name }} <span class="detail-name-sub" v-if="detail.name.sub">{{ detail.name.sub }}</span></div>
            <!-- using noname so we don't bold the Description text-->
            <div :class="[detail.name.name ? 'detail-value' : 'detail-value-noname', `text-${detail.value.highlight}`]">{{ detail.value.value }}</div>  
          </div>
          <template v-for="s in detail.sub_details" :key="s.name.name">
            <div class="sub-detail-row" v-if="s.type == 'detail'">
              <div class="sub-detail-name">{{ s.name.name }} <span class="sub-detail-name-sub" v-if="s.name.sub">{{ s.name.sub }}</span></div>
              <div class="sub-detail-value" :class="`text-${s.value.highlight}`">{{ s.value.value }}</div>
            </div>
          </template>
        </div>
        <!-- end regular detail -->

        <!-- table :class="`text-${v.highlight}`" -->
        <div class="table-base" v-if="detail.type == 'table'">
          <div class="table-title" v-if="'name' in detail">{{ detail.name.name }}</div>
          <div class="table-grid-base bd" :class="[detail.columns.length == 2 ? 'table-grid-2' : 'table-grid-3']">
            <div class="table-grid-name"></div>
            <div class="table-grid-header" v-for="column in detail.columns" :key="column">{{ column }}</div>

            <template v-for="(row, index) in detail.rows" :key="index">
              <div class="table-grid-name">{{ row.name }}</div>
              <!-- if highlight, use the color, otherwise make the company value bold and sector values gray -->
              <div class="table-grid-value"
                :class="[v.highlight ? `text-${v.highlight}` : [indexx == 0 ? 'text-3' : 'text-gray-nobold']]"
                v-for="(v, indexx) in row.values"
                :key="indexx"
              >{{ v.value }}</div>
            </template>
          </div>
        </div>
        <!-- end table -->

      </template>
    </div>
    <!-- box-content -->

    <!-- box-content with company details and logo -->
    <div class="box-content bd" v-if="data.name == 'Company'">
      <!-- company details and logo flex container -->
      <div class="details-with-logo bd">

        <!-- company details -->
        <div class="company-details">
          <template v-for="(detail, index) in data.details.slice(0,4)" :key="index">
            <div class="detail">
              <div class="detail-main-row">
                <div class="detail-name">{{ detail.name.name }} <span class="detail-name-sub" v-if="detail.name.sub">{{ detail.name.sub }}</span></div>
                <!-- using noname so we don't bold the Description text-->
                <div :class="[detail.name.name ? 'detail-value' : 'detail-value-noname', `text-${detail.value.highlight}`]">{{ detail.value.value }}</div>  
              </div>
              <template v-for="s in detail.sub_details" :key="s.name.name">
                <div class="sub-detail-row" v-if="s.type == 'detail'">
                  <div class="sub-detail-name">{{ s.name.name }} <span class="sub-detail-name-sub" v-if="s.name.sub">{{ s.name.sub }}</span></div>
                  <div class="sub-detail-value" :class="`text-${s.value.highlight}`">{{ s.value.value }}</div>
                </div>
              </template>
            </div>
          </template>
        </div>

        <!-- company logo if it exists -->
        <div class="logo-container" v-if="data.logo_image">
          <img class="company-logo" :src="data.logo_image" width="128" height="128" alt="" onerror="this.style.display='none'"/>
        </div>

      </div>

      <!-- expand-text detail -->
      <template v-for="(detail, index) in data.details.slice(4)" :key="index">
        <div class="detail" v-if="detail.type == 'expand-text'">
          <div class="detail-main-row">
            <div class="detail-expand-text">{{ detail.name.name }}<span id="expand-text-button" v-if="detail.value" @click="expandText()">...More</span>
              <span id="expand-text-more" v-if="detail.value">{{ detail.value.value }}</span>
            </div>
          </div>
        </div>
      </template>

    </div>
    <!-- company details box-content -->

  <InfoDialog
          v-if="showInfoDialog"
          v-bind:cancel="cancelDialog"
          v-bind:infoType="infoDialogType" />

  </div>
</template>

<script>
import InfoCircle from "@/components/InfoCircle.vue";
import InfoDialog from "@/components/InfoDialog.vue";

export default {
  name: "QuoteDetailBox",
  props: ["data"],
  components: {
    InfoDialog,
    InfoCircle
  },
  data() {
    return {
      // boxshadow: "box-shadow-neutral",
      showInfoDialog: false,
      infoDialogType: "",
    };
  },
  created() {
    // can make BE api calls here; have access to the reactive data properties
    // DOM has not been mounted yet, so you can manipulate that
    
    // this.calculateBoxShadow();
  },
  computed: {
    boxShadoww() {
      if (this.data.score === undefined) {
        return "box-shadow-neutral";
      }
      let score = this.data.score
      if (score >= 1 && score <= 20) {
        return "box-shadow-darkred";
      } else if (score >= 21 && score <= 40) {
        return "box-shadow-lightred";
      } else if (score >= 41 && score <= 60) {
        return "box-shadow-neutral";
      } else if (score >= 61 && score <= 80) {
        return "box-shadow-lightgreen";
      } else if (score >= 81 && score <= 100) {
        return "box-shadow-darkgreen";
      }
      return "box-shadow-neutral"
    }
  },
  methods: {
    expandText() {
      let expandTextMore = document.getElementById("expand-text-more");
      let expandTextButton = document.getElementById("expand-text-button");
      expandTextMore.style.display = "inline"
      expandTextButton.style.display = "none"
    },
    cancelDialog() {
      const scrollY = document.body.style.top;
      this.showInfoDialog = false;
      document.body.classList.remove('show-overlay');
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      this.infoDialogType = "";
    },
    openInfoDialog(infoType) {
      this.infoDialogType = infoType;
      let x = `-${window.scrollY}px`;
      this.showInfoDialog = true;
      document.body.classList.add('show-overlay');
      document.body.style.top = x;
    }
  //   calculateBoxShadow() {
  //     if (this.data.score === undefined) {
  //       this.boxshadow = "box-shadow-neutral"
  //       return;
  //     }
  //     let score = this.data.score
  //     if (score >= 1 && score <= 20) {
  //       this.boxshadow = "box-shadow-darkred"
  //     } else if (score >= 21 && score <= 40) {
  //       this.boxshadow = "box-shadow-lightred"
  //     } else if (score >= 41 && score <= 60) {
  //       this.boxshadow = "box-shadow-neutral"
  //     } else if (score >= 61 && score <= 80) {
  //       this.boxshadow = "box-shadow-lightgreen"
  //     } else if (score >= 81 && score <= 100) {
  //       this.boxshadow = "box-shadow-darkgreen"
  //     } else {
  //       this.boxshadow = "box-shadow-neutral"
  //     }
  //   }
  }
};
</script>

<style scoped lang="scss">
.bd {
  // border: 1px solid rgb(168, 168, 168);
}

.quote-detail-box {
  min-width: 300px; // set the min width
  // width: fit-content; // if it's larger then min, allow it grow; **this seems to only make the width fit the content inside
  padding: 20px 15px;
  border-radius: var(--border-radius-quote-detail-box);
  -moz-border-radius: var(--border-radius-quote-detail-box);

  //box-shadow: 0 3px 8px -5px var(--quotebox-shadow); // https://css-tricks.com/almanac/properties/b/box-shadow/
  //box-shadow: 0 3px 8px 0px var(--font-color-up-green);
  //box-shadow: 0 3px 8px 0px var(--font-color-down-red);

  //box-shadow: 0 3px 8px -3px var(--quotebox-shadow); // Last number (Spread) was -2 before

  // Other examples
  //box-shadow: rgba(128, 111, 209, 0.20) 0px 4px 16px, rgba(128, 111, 209, 0.10) 0px 8px 32px;
  //box-shadow: rgba(111, 209, 132, 0.3) 0px 4px 16px, rgba(111, 209, 132, 0.30) 0px 8px 32px;
}
@media (max-width: 400px) { // should this be 450?
  .quote-detail-box {
    //padding: 15px 10px; // reduce padding for smaller screens
    padding: 20px 10px 20px 15px; // reduce padding for smaller screens
  }
}
.box-shadow-neutral {
  box-shadow: 0 3px 8px -3px var(--quotebox-shadow);
}
.box-shadow-lightred {
  // box-shadow: 0 3px 8px -3px #d10000; // first attempt, maybe too light?
  // box-shadow: 0 3px 10px -3px #d10000;
  box-shadow: 0 0px 11px -3px #d10000;
}
.box-shadow-darkred {  // Symbol BX for red boxes
  // box-shadow: 0 3px 11px -2px #d10000;
  // box-shadow: 0 3px 13px -1px #d10000;
  box-shadow: 0 0px 15px -1px #d10000;
}
.box-shadow-lightgreen {
  // box-shadow: 0 2px 8px -3px #04A93D;
  // box-shadow: 0 2px 10px -2px #04A93D
  box-shadow: 0 0px 10px -2px #04A93D
}
.box-shadow-darkgreen {  // Symbol EOG for green boxes
  // box-shadow: 0 2px 11px -2px #04A93D;
  // box-shadow: 0 2px 14px -1px #04A93D;
  box-shadow: 0 0px 15px -1px #04A93D;
}

.box-name-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.box-name {
  font-family: var(--font-roboto);
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
}

.info-button {
  padding: 0px 2px 0px 1px;
  margin-top: -5px;
  margin-right: 1px;
  height: 22px;
  cursor: pointer;
}

.box-content {
  // Note, we need to update company-details class also
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 2.5rem;

  font-family: var(--font-yantram);
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--font-color-closeblack);
}

/////// table detail within box ///////

.table-base {
  display: flex;
  flex-direction: column;
  row-gap: 0rem;

  background-color: #f8f8f8; // TODO make this a variable
  padding: 1rem 0rem 1rem 0.7rem;
  border-radius: 10px;
  -moz-border-radius: 10px;
}

.table-title {
  font-size: 1.3rem;
  letter-spacing: 0.2px; // so the letters are not so squished
  font-weight: 700;
  line-height: 2.5rem; // shrink line height so title is closer to table
  //padding-top: 2px; // add a little extra spacing above
  font-family: var(--font-roboto); // Was Yantr font with size 1.4rem (or 1.45) and spacing 0.3px
}

.table-grid-base {
  display: grid;
  justify-items: center;
  row-gap: 10px;
  font-size: 1.4rem;

  //background-color: #f8f8f8;
  //padding: 1rem 0rem 1rem 0.7rem;
  //border-radius: 10px;
  //-moz-border-radius: 10px;
}

.table-grid-2 {
  grid-template-columns: 12rem auto auto;
}

.table-grid-3 {
  grid-template-columns: 12rem auto auto auto;
}

.table-grid-header {
  font-family: var(--font-roboto);
  font-size: 1.3rem;
  font-weight: 500;  // was 700 before adding the title
}

.table-grid-name {
  font-size: 1.5rem;
  justify-self: start;
}

.table-grid-value {
}

///////// regular detail and sub-detail in box /////////

.detail {
  --detail-name-width: 12rem;
}

.detail-main-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.detail-name {
  width: var(--detail-name-width);
}
.detail-name-sub {
  font-size: 1.1rem;
}
.detail-value {
  font-family: var(--font-roboto);
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--font-color-black);
}
.detail-value-noname { // is this still used now that we have detail-expand-text?
  font-size: 1.5rem;
  font-weight: 400;
}

.sub-detail-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
}
.sub-detail-name {
  width: var(--detail-name-width);
  padding-left: 10px;
  font-size: 1.4rem;
}
.sub-detail-name-sub {
  font-size: 1.1rem;
}
.sub-detail-value {
  font-family: var(--font-roboto); // for the value
  font-size: 1.3rem;
  color: var(--font-color-black);
}

////////// company details with logo //////////

.details-with-logo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.company-details {
  // Same as box-content class
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 2.5rem;
}

.logo-container {
  flex-grow: 1;
  // Center image in div
  display: grid;
  place-items: center center;
}

.company-logo {
  margin: 0px 25px 30px 25px;
}

.detail-expand-text {
  font-size: 1.5rem;
  font-weight: 400;
}
#expand-text-more {
  display: none;
}
#expand-text-button {
  color: var(--font-color-darkpurple);
  cursor: pointer;
}
#expand-text-button:hover {
  color: var(--font-color-turquoise);
}

////////// highlights //////////

// Dynamically add class names:
// https://michaelnthiessen.com/dynamically-add-class-name/
// https://michaelnthiessen.com/conditional-class-binding-vue
// https://vuejs.org/guide/essentials/class-and-style.html#binding-html-classes

.text-undefined {
}

.text-5 {
  color: var(--font-color-dark-green2);
  font-family: var(--font-roboto);
  font-weight: 600;

  background-color: var(--detail-color-lightgreen2);
  padding: 0.2rem 0.5rem 0.2rem 0.6rem;
  border-radius: 15px;
  -moz-border-radius: 15px;
}

.text-4 {
  color: var(--font-color-dark-green2);
  font-family: var(--font-roboto);
  font-weight: 600;
}

.text-3 { // just bold with no color
  color: var(--font-color-black);
  font-family: var(--font-roboto);
  font-size: 1.4rem;
  font-weight: 500;
}

.text-2 {
  color: var(--font-color-dark-red2);
  font-family: var(--font-roboto);
  font-weight: 600;
}

.text-1 {
  color: var(--font-color-dark-red2);
  font-family: var(--font-roboto);
  font-weight: 600;

  background-color: var(--detail-color-lightred2);
  padding: 0.2rem 0.5rem 0.1rem 0.6rem;
  border-radius: 15px;
  -moz-border-radius: 15px;
}

.text-10 {
  color: var(--font-color-graymiddark);
  background-color: var(--layout-color-almostwhite);
  padding: 0.2rem 0.5rem 0.1rem 0.6rem;
  border-radius: 15px;
  -moz-border-radius: 15px;
}

.text-gray-nobold {
  color: var(--font-color-closeblack);
  font-family: var(--font-roboto);
  font-size: 1.4rem;
  font-weight: 400;
}

</style>
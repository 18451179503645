<template>
  <div class="app-all">
    <!-- Header -->
    <app-header v-if="!$route.meta.hideHeader" />
    <!-- Content -->
    <!-- the :key thing makes the page reload when navigating between the same component -->
    <router-view class="app-content" :key="$route.fullPath" />
    <!-- Footer -->
    <app-footer v-if="!$route.meta.hideFooter" />
  </div>
</template>

<script>
import HeaderWithNav from "@/components/HeaderWithNav.vue";
import Footer from "@/components/Footer.vue";
import { useHead } from "@unhead/vue";

export default {
  name: "app",
  components: {
    "app-header": HeaderWithNav,
    "app-footer": Footer,
  },
  setup() {
    useHead({
      // title: "stocknito",
      titleTemplate: (title) => !title ? 'Stocknito' : `${title}`,
      meta: [
        {
          name: "description",
          content: "A better way of analyzing stocks and investments. See what's possible with Stocknito.",
        },
      ],
    })
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Noto+Sans:wght@400&family=Nunito:wght@700&family=Open+Sans:wght@400;600;700&family=Yantramanav:wght@400&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
//@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
//@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap"); // not bad for title
//@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap"); // for nav text?
//@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap"); // for nav text
//@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
//@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500&display=swap");
//@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@400;500&display=swap");

//@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); // good for text
//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap'); // also good for text
//@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari&display=swap'); // not bad

:root {
  // Define layout values
  --content-width: 750px; // 70rem;
  --content-min-horz-margin: 20px;
  --content-min-phone-horz-margin: 10px; // used when screen width <= 400
  --content-vert-top-margin: 40px;
  --content-vert-bottom-margin: 80px;

  // Max width of search box form (not sure how this is used)
  --search-width: 250px;

  // Define border radius
  --border-radius-text-input: 20px; // radius around contact-us text boxes
  --border-radius-info-text-box: 40px; // radius around info text boxes
  --border-radius-quote-detail-box: 15px; // radius around quote detail boxes and info dialog

  // Define fonts
  --font-content: "Open Sans", Verdana, sans-serif; // Verdana has over 99% availability
  --font-content-p: "Noto Sans", Verdana, sans-serif;
  --font-roboto: "Roboto", sans-serif;
  --font-yantram: "Yantramanav", sans-serif;
  --font-cursive: "Pacifico", cursive, script;
  --font-nav-nunito: "Nunito", sans-serif;
  --font-nav-opensans: "Open Sans", Verdana, sans-serif;
  --font-code-courier: "Courier New", Courier, monospace;
  // --font-content: Avenir,Arial,Helvetica,sans-serif;  // avenir is not a free font
  --font-size-regular: 1.6rem;
  --font-content-word-spacing: 0.3rem;
  --font-content-line-height: 1.8;
  // Smaller text, like in info dialogs
  --font-size-smaller: 1.4rem;
  --font-smaller-word-spacing: 0.3rem;
  --font-smaller-line-height: 1.6;

  // Define font colors
  --font-color-black: #000000;
  --font-color-blue: #072ee4;
  --font-color-neutralblue: #1246e1;
  --font-color-lightblue: #009dff; // used in link hover
  --font-color-darkblue: #030fb5;  // currently not used
  --font-color-turquoise: #37d7d7;
  --font-color-pink: #e72351;
  --font-color-purple: #5848de;
  --font-color-darkpurple: #3a0ca3;
  --font-color-white: #ffffff; // used in quote page, bar
  --font-color-almostwhite: #ebebeb; // used in quote page, bar
  --font-color-graymid: #808080;  // used in search results box
  --font-color-graymid1: #767676;  // used in quote box display name; lowest gray while keeping accessible
  --font-color-graymid2: #6a6a6a;  // used in desktop nav text
  --font-color-graymiddark: #575757; // #464646
  --font-color-lightblack: #404040; // used in code segment
  --font-color-closeblack: #2e2e2e; // used in quote details
  --font-color-almostblack: #161616;
  --font-color-content-text: #233042;

  --font-color-up-green: #04A93D; // used in quote box
  --font-color-down-red: #EA393B; // a tad darker: dd2d2d
  --font-color-dark-green: #008930; //#006724; // used in quote box
  --font-color-dark-red: #d10000; //#E20000;//#B60000; // a tad darker: dd2d2d
  --font-color-dark-green2: #00851F; // used in detail box
  --font-color-dark-red2: #CD0000; // used in detail box

  // Define other colors
  --submit-button: var(--font-color-neutralblue); // submit button for contact form
  --submit-button-disabled: #c5c5c5; // light gray
  --quotebox-shadow: #806fd1; // #948ac4 lighter purple
  --selection-box-lightpurple: #e9d6ff; // light purple, home quote selection boxes
  --searchbutton-fill-color-white: #ffffff;
  --searchbutton-color-lightgray: #C5C5C5;
  --quotedetailborder-color-lightgray: #ededed; // #dcdbdb, #C5C5C5 previous line color
  --searchbutton-hover-color-gray: #777777;
  --layout-color-almostwhite: #f4f4f4; // used in search results box; also in grey detail bubble;
                                         // home page quote selection boxes 
  --page-color-background: #ffffff;
  --textbox-color-lightgreen: #cdffdb; // text box for info text
  --textbox-color-lightpurple: #e9d6ff; // same color as selection-box
  // Detail bubbles
  --detail-color-lightgreen: #daffe8;
  --detail-color-lightgreen2: #e3ffea; // for detail box
  --detail-color-green: #99e9b5;
  --detail-color-lightred: #ffd3d3;
  --detail-color-lightred2: #feeaea;
  --detail-color-red: #ffa7a7;
  --detail-color-lightorange: #ffe2c6;//#ffebc6;
  // --detail-color-orange: #FFCC80; //#ffd396; // not used
  --detail-color-lightyellow: #FFF9C4;
  --detail-color-yellow: #FFF59D; // #fff8ac;
  // Nav
  --nav-color-hamburger: var(--font-color-blue);
  --nav-color-background: var(--font-color-blue); //#6858ff; // lighter purple, color before
  --nav-color-mobile-text: var(--font-color-white);
  --nav-color-desktop-text: var(--font-color-blue);
  --nav-color-desktop-hover-text: var(--font-color-lightblue);
  --nav-color-desktop-dropdown-background: var(--font-color-white);

  // Temp colors
  --temp-color-turquoise: #28e1c2;  // from Grip site,
  --temp-color-neutralblue: #1246e1; // from Grip site, good for header text
  --temp-color-lightblue: #08ceff;  // from Propel site, could be for link over text, good with black as background
  --temp-color-lightblue2: #009dff; // from Propel site
  --temp-color-lightblue3: #0080ff; // from Propel site
  --temp-color-darkblue: #0c0fb5;  // from Astrix site, for heading or link text?
}

*,
*:before,
*:after {
  box-sizing: inherit; // is this so everying inherits the box-sizing from parent?
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
}

html {
  box-sizing: border-box; // so width includes padding and border (default is content-box)
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-size: 62.5%;
  // height: 100%;   // what was this used for?
}

body {
  background-color: var(--page-color-background);
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -webkit-backface-visibility: hidden; // what does this do?
  // overflow-x: hidden;
  // min-height: 100%;
  // height: 100%;
}

p {
  font-family: var(--font-content-p);
  word-spacing: var(--font-content-word-spacing);
  line-height: var(--font-content-line-height);
  color: var(--font-color-content-text);
}

a {
  color: var(--font-color-blue);
  cursor: pointer;
  background: transparent;
  text-decoration: none;
}
a:visited {
  color: var(--font-color-blue);
}
a:hover {
  color: var(--font-color-lightblue);
}

.app-all {
  display: flex;
  flex-direction: column;
  min-height: 100vh; // might have problems in mobile

  font-family: var(--font-content);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-content {
  flex: 1;
}

.show-overlay {
  position: fixed;
  left: 0;
  right: 0;
  // overflow-y: scroll; // render disabled scroll bar to keep the same width
  overflow-y: hidden; // Disabled scrolling still makes a mess with its width. Hiding it does the trick. ??
}

</style>

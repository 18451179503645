<template>
  <div class="not-found-text">
    <p>
      <span class="fourofour-text">404</span>
      Dude what are you looking for? There's nothing here.
    </p>
  </div>
</template>

<script>
import { useHead } from '@unhead/vue'

export default {
  name: "PageNotFound",
  setup() {
    useHead({
      title: '404 Page Not Found',
      meta: [
        { name: 'description', content: 'Page not found.' },
      ],
    })
  },
};
</script>

<style scoped lang="scss">
.not-found-text {
  display: flex; // so we can center horz and vert
  justify-content: center;
  align-items: center;
  margin: 0 var(--content-min-horz-margin);

  font-size: var(--font-size-regular);
}

.fourofour-text {
  font-weight: 700;
  color: var(--font-color-pink);
}
</style>
<template>
  <div class="quote" ref="quoteRef">
    
    <div class="name-bar">
      <div class="name-bar-row1">
        <div class="symbol">{{ quoteSymbol }}</div>
        <div class="type">{{ quote.type }}</div>
      </div>
      <div class="name-bar-row2">
        <div class="name">{{ quoteName }}</div>
        <div class="country">{{ quote.country }}</div>
      </div>
    </div>

    <div class="quote-data">
      <div class="price-bar">
        <div class="close">{{ quoteClose }}</div>
        <div class="changep">{{ quoteChangePercent }}</div>
      </div>
      <div class="date-bar">
        <div class="date-currency">
          <div class="date">{{ rtQuote.date_string }},</div>
          <div class="currency">{{ quote.currency }}</div>
        </div>
        <div class="change">{{ quoteChange }}</div>
      </div>

      <div class="chart-movement-box" v-if="chartMovementText" v-bind:title="chartMovementTitleText" >
        <div class="chart-movement-content">
            <div>
              <SvgArrowUp v-show="chartMovementArrow === 1" v-bind:fillColor="chartMovementArrowColor" />
              <SvgArrowDown v-show="chartMovementArrow === 2" v-bind:fillColor="chartMovementArrowColor" />
            </div>
            <div class="chart-movement-text">{{ chartMovementText }}</div>
        </div>
      </div>

      <div class="chart">
        <!-- Force re-render with key, https://michaelnthiessen.com/force-re-render/ -->
        <QuoteLineChart class="linechart" v-if="chartDataToUse" :data="chartDataToUse" :key="chartDataToUse" />
        <!-- <LineChart
          v-if="this.chartDataLoaded && mounted"
          :data="chartData"
          :parentWidth="quoteRefWidth"
          :styles="chartStyleComputed"
          class="linechart"
          id="linechart"
          ref="linechart"
        /> -->
        <!-- <LineChart v-if="this.chartDataLoaded" :data="chartData" :styles="{width: '100%', height: '100%', position: 'relative'}" class="linechart" id="linechart" /> -->
      </div>

      <div class="chart-selector">
        <div class="chart-selector-bubble">
          <span><a @click="getChartData('chart5day')" v-show="chartDataRange != 'chart5day'">5D</a><span v-show="chartDataRange === 'chart5day'">5D</span></span>
          <span class="chart-selector-space"><a @click="getChartData('chart1month')" v-show="chartDataRange != 'chart1month'">1M</a><span v-show="chartDataRange === 'chart1month'">1M</span></span>
          <span class="chart-selector-space"><a @click="getChartData('chart6month')" v-show="chartDataRange != 'chart6month'">6M</a><span v-show="chartDataRange === 'chart6month'">6M</span></span>
          <span class="chart-selector-space"><a @click="getChartData('chart1year')" v-show="chartDataRange != 'chart1year'">1Y</a><span v-show="chartDataRange === 'chart1year'">1Y</span></span>
          <span class="chart-selector-space"><a @click="getChartData('chart3year')" v-show="chartDataRange != 'chart3year'">3Y</a><span v-show="chartDataRange === 'chart3year'">3Y</span></span>
          <span class="chart-selector-space"><a @click="getChartData('chart5year')" v-show="chartDataRange != 'chart5year'">5Y</a><span v-show="chartDataRange === 'chart5year'">5Y</span></span>
          <span class="chart-selector-space"><a @click="getChartData('chart10year')" v-show="chartDataRange != 'chart10year'">10Y</a><span v-show="chartDataRange === 'chart10year'">10Y</span></span>
        </div>
      </div>

      <!-- regular detail -->
      <div class="details">
        <div class="detail" v-for="d in quote.details" :key="d.name">
          <div class="detail-border"></div>
          <div class="detail-main-row">
            <div class="detail-name">{{ d.name }}</div>
            <div class="detail-value" :class="d.highlight_value">{{ d.value }}</div>
          </div>
          <div class="sub-detail" v-for="s in d.sub_details" :key="s.name">
            <div class="sub-detail-name">{{ s.name }}</div>
            <div class="sub-detail-value" :class="s.highlight_value">{{ s.value }}</div>
          </div>
        </div>
      </div>

      <!-- detail boxes -->
      <div class="detail-boxes">
        <!-- <QuoteDetailBox class="detail-box" v-for="(d, index) in quote.detail_sections" :key="index" v-bind:data="d" /> -->
        <template v-for="(d, index) in quote.detail_sections" :key="index">
          <QuoteDetailBox v-bind:data="d" :class="d.wide && 'detail-box-wide'"/>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import { fetchMixin } from "@/mixins/fetch.js";
import QuoteLineChart from "@/components/QuoteLineChart";
import QuoteDetailBox from "@/components/QuoteDetailBox.vue";
import SvgArrowUp from "@/components/SvgArrowUp";
import SvgArrowDown from "@/components/SvgArrowDown";
import { Chart as ChartJS, LineController, TimeScale, LinearScale, PointElement, LineElement, Tooltip, Filler, LogarithmicScale } from "chart.js";

// https://www.chartjs.org/docs/master/getting-started/integration.html#bundlers-webpack-rollup-etc
ChartJS.register(LineController, TimeScale, LinearScale, PointElement, LineElement, Tooltip, Filler, LogarithmicScale);
//  Chart.register(...registerables);

const numFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export default {
  name: "Quote",
  mixins: [fetchMixin],
  props: ["quote", "rtQuote", "fundData", "chartData", "chartDataLoaded"], // TODO chartDataLoaded is not used
  components: {
    QuoteLineChart,
    QuoteDetailBox,
    SvgArrowUp,
    SvgArrowDown
  },
  data() {
    return {
      quoteGreen: "#04A93D", // --font-color-up-green
      quoteRed: "#EA393B",   // --font-color-down-red
      quoteRefWidth: 0, // used to determine chart width
      mounted: false, // after true, we know the quoteRefWidth
      chartDataToUse: this.chartData, // set initial chart data to value from prop
      chartDataRange: this.quote?.chart_start ?? "chart1year", // optional chainging with nullish coalescing operator

      showChartMovementText: false, // TODO not used at the moment
      chartMovementText: "",
      chartMovementArrow: 0, // 0 no arrow, 1 up, 2 down
      chartMovementArrowColor: "#161616", // --font-color-almostblack
      chartMovementTitleText: "",
    };
  },
  created() {
    // can make BE api calls here; have access to the reactive data properties
    // DOM has not been mounted yet, so you can manipulate that
    this.calculateChartMovement();
  },
  mounted() {
    // console.log("mounted; parent width:", this.$refs.quoteRef.clientWidth)
    // this.quoteRefWidth = this.$refs.quoteRef.clientWidth; // TODO was used in the old chart
    // this.mounted = true;
    // window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    // beforeDestroy() // vue2
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    // console.log("removing event listener")
    // window.removeEventListener("resize", this.handleResize); // TODO was used in the old chart
  },
  computed: {
    chartStyleComputed: function () { // TODO This was used in the old chart, remove at some point
      // TODO what chart style do we need?
      // let winWidth = window.innerWidth // to get the window width? (older method)
      if (this.quoteRefWidth == 0) {
        return null;
      }
      // console.log("div width:", this.quoteRefWidth)
      // console.log("parent width:", this.$refs.quoteRef.clientWidth)
      var width = this.quoteRefWidth - 20;
      let height = (width / 3.0) * 1.5; // it was *1.8 before, not sure where that came from
      // console.log(width + "," + height)
      var chartStyle = {
        width: width + "px",
        height: height + "px",
        position: "relative",
      };
      return chartStyle;
    },
    quoteSymbol: function () {
      return this.quote.symbol.toUpperCase();
    },
    quoteClose: function () {
      if (this.rtQuote.close === undefined) {
        return "-";
      }
      return this.rtQuote.close.toLocaleString("en", numFormatOptions);
      // old methods of just returning 2 decimal places
      // return this.rtQuote.close.toFixed(2)
    },
    quoteChange: function () {
      if (this.rtQuote.change === undefined) {
        return "";
      }
      let root = document.documentElement;
      var changeStr = this.rtQuote.change.toFixed(2);
      if (this.rtQuote.change > 0) {
        changeStr = "+" + changeStr;
        root.style.setProperty("--quote-color", this.quoteGreen);
      } else if (this.rtQuote.change < 0) {
        // we're assuming it already has the negative dash
        root.style.setProperty("--quote-color", this.quoteRed);
      }
      return changeStr;
    },
    quoteChangePercent: function () {
      if (this.rtQuote.change_percent === undefined) {
        return "0%";
      }
      var change = this.rtQuote.change_percent;
      var changeStr = change.toFixed(2);
      if (changeStr === "0.00") {
        return "0%";
      }
      if (change > 0) {
        changeStr = "+" + changeStr;
      }
      // else if (this.quote.change_p < 0) {
      // we're assuming it already has the negative dash
      // }
      return changeStr + "%";
    },
    quoteRTDate: function () {  // Currently not used; getting string from BE
      if (this.rtQuote.date === undefined) {
        return "-";
      }
      var rtDate = new Date(this.rtQuote.date)
      return rtDate.toString()
    },
    quoteName: function () {
      return this.quote.name.replaceAll('ﾙ', ' ').trim()
    }
  },
  methods: {
    calculateChartMovement() {
      this.chartMovementText = "" // Not sure if we should clear the text up here
      this.chartMovementArrow = 0

      if (this.chartDataToUse === undefined) {
        return
      }
      var chartLen = this.chartDataToUse.length;
      if (chartLen < 2) {
        return // not enough values
      }

      // find max and min of chart
      var lastValue = this.chartDataToUse[0].close;
      var max = lastValue;
      var maxDate = this.chartDataToUse[0].date
      var min = lastValue;
      var minDate = this.chartDataToUse[0].date

      for (let i = 0; i < chartLen; i++) {
        var val = this.chartDataToUse[i].close
        var date = this.chartDataToUse[i].date
        if (val > max) { max = val; maxDate = date; }
        if (val < min) { min = val; minDate = date; }
      }

      let diffFromMax = (max - lastValue)/max * 100.0 // calc % drop from max
      let diffFromMin = (lastValue - min)/min * 100.0 // calc % gain from min

      if (diffFromMin > diffFromMax) { // Gone up
        var percentUp = diffFromMin.toFixed(2) + "%"
        this.chartMovementText = percentUp + " from Low"
        this.chartMovementArrow = 1
        this.chartMovementArrowColor = this.quoteGreen
        this.chartMovementTitleText = "Up " + percentUp + " from low of " + min + " on " + minDate + " during chart period"
      }
      else if (diffFromMax > diffFromMin) { // Gown down
        var percentDown = diffFromMax.toFixed(2) + "%"
        this.chartMovementText = percentDown + " from High"
        this.chartMovementArrow = 2
        this.chartMovementArrowColor = this.quoteRed
        this.chartMovementTitleText = "Down " + percentDown + " from high of " + max + " on " + maxDate + " during chart period"
      }
    },
    async getChartData(chartStartDate) {
      if (this.quote.symbol == "") {
        return
      }
      var exactTicker = this.quote.symbol + "." + this.quote.exchange_code

      // Make the request
      var chartQuotePath = config.apiUrl + config.apiPathHistQuote + "/" + exactTicker + "?start-date=" + chartStartDate;
      let { data, statusCode } = await this.makeRequest(chartQuotePath);
      if (!data || statusCode != 200) {
        // console.log("error getting chart data, status code: ", statusCode, error)
        return;
      }

      // Get chart data if it exists
      if (data != null && data.length > 0) {
        var existingDate = new Date(this.chartDataToUse[0].date)
        var newDate = new Date(data[0].date)

        if (existingDate.toDateString() == newDate.toDateString()) {  // dates are equal, let's use existing value
          data[0] = this.chartDataToUse[0]
        } else if (existingDate > newDate) { // dates are converted to numbers
          // last element in existing chart is newer
          data.splice(0, 0, this.chartDataToUse[0])
          data.pop()
        } // else if newDate > existingDate don't do anything
        this.chartDataRange = chartStartDate; // chart1year, etc
        localStorage.setItem('dquoteChartStartDate', chartStartDate); // save in local storage
        this.chartDataToUse = data;

        // Recaculate chart movement text
        this.calculateChartMovement();
      }
    }

    // whenever the document is resized, re-set the 'clientWidth' variable
    // (event)

    // This was used for the old LineChart, remove at some point
    // handleResize() {
    //   if (this.$refs.quoteRef.clientWidth) {
    //     this.quoteRefWidth = this.$refs.quoteRef.clientWidth;
    //     if (this.$refs.linechart) {
    //       this.$refs.linechart.renderLineChart(false);
    //     }
    //   }
    // },
  }
};
</script>

<style scoped lang="scss">
// Fonts
// Roboto works well for name, quote, etc
// Open Sans and Poppins work well for details and numbers
// Open Sans is probably a little more condensed then Poppins
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700');

:root {
  --quote-color: #000000;
}

.quote {
  width: var(--content-width);
  display: grid;
  grid-template-columns: 1fr;
  margin-left: 10px;
  margin-right: 10px;
  font-family: var(--font-roboto);
}

.name-bar {
  background-color: var(--font-color-blue);
  //background-color: #0000CD;
  //background-color: #153AE3; // #0028DC;
  //background-color: #193CEB;
  padding-top: 2rem;
  padding-right: clamp(15px, 2rem, 25px);
  padding-bottom: 1.5rem;
  padding-left: clamp(15px, 2rem, 25px);
  margin-bottom: 2rem;
  //border-radius: 2.5rem;
  border-radius: 2.0rem;
}
.name-bar-row1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2px;
}
.symbol {
  font-size: 3.2rem;
  font-weight: 700;
  color: var(--font-color-white);
}
.type {
  font-size: 1.8rem;
  font-weight: 300;
  color: var(--font-color-almostwhite);
}
.name-bar-row2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.name {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--font-color-white);
}
.country {
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--font-color-almostwhite);
}

.quote-data {
  // everything below the name-bar
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 540px) { // on phone decrease the left/right margins to 5px
  .quote-data {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.price-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.25rem;
}
.close {
  font-size: 3.4rem;
  font-weight: 500; // TODO update this
}
.changep {
  font-size: 3.2rem;
  color: var(--quote-color);
}

.date-bar {
  // includes date, currency and change
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2.5rem;
}
.date-currency {
  display: flex;
  font-size: 1.2rem;
  color: var(--font-color-graymid);
}
.date {
}
.currency {
  margin-left: 3px;
}
.change {
  font-size: 2rem;
  color: var(--quote-color);
  line-height: 0.8; // remove extra space above and below
}

.chart-movement-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.2rem;
}
.chart-movement-content {
  display: flex;  // so arrow and text sit on same line
  flex-direction: row;
  // bubble around
  padding: 0.4rem 0.7rem;
  background-color: var(--layout-color-almostwhite);
  border-radius: 15px;
  -moz-border-radius: 15px;
}
.chart-movement-text {
  margin-left: 0.5rem; // space between arrow and text
  font-size: 1.2rem;
  color: var(--font-color-graymid);
}

.chart-selector {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--font-color-closeblack);

  display: flex;
  flex-direction: row;
  justify-content: center;  // to align items inside to the right
  margin-bottom: 4.0rem;
}
.chart-selector-bubble {
  display: inline-block;
  padding: 0.8rem 1.7rem;
  // background-color: var(--layout-color-almostwhite);
  border-radius: 15px;
  -moz-border-radius: 15px;

  box-shadow: 0 3px 8px -5px var(--quotebox-shadow);
}
.chart-selector a {
  color: var(--font-color-graymid);
}
.chart-selector a:hover {
  color: var(--font-color-pink);
}
.chart-selector-space {
  margin-left: 2.4rem;
}

.chart {
  // div around the line chart
  margin-bottom: 2rem;
}
.linechart {
  // For the LineChart component
  // With this margin enabled, it makes the chart really small after vue-chartjs 5 upgrade
  // Why did we even have it in the first place?
  // margin: 0 auto;
}

.details {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: clamp(35px, 5.0rem, 60px);
  row-gap: 2.5rem;
}
@media (max-width: 589px) { // when details go down to 1 column, add extra margin
  .details {
    margin-left: 30px; // this in addition to 5+10px on either side
    margin-right: 30px;
  }
}
@media (max-width: 400px) { // should this be 450?
  .details {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.detail {
  justify-content: left;
  font-size: 1.8rem;
  font-family: var(--font-content);
}
.detail-border {
  border-bottom: 1px solid var(--quotedetailborder-color-lightgray);
  margin-bottom: 1rem;
}
.detail-main-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.detail-name {
  color: var(--font-color-closeblack);
  font-family: var(--font-yantram);
  font-size: 1.5rem;
  font-weight: 400;
}
.detail-value {
  font-family: var(--font-roboto);
  font-size: 1.4rem;
  font-weight: 500;
}
.sub-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-family: var(--font-roboto); // for the value
  font-size: 1.3rem;
}
.sub-detail-name {
  margin-left: 10px;
  color: var(--font-color-closeblack);
  font-family: var(--font-yantram);
  font-size: 1.4rem;
}
.sub-detail-value {
  color: var(--font-color-closeblack);
}
.detail-bubble {
  padding: 0.4rem 0.7rem;
  border-radius: 15px;
  -moz-border-radius: 15px;
}
.detail-gray {
  background-color: var(--layout-color-almostwhite);
  color: var(--font-color-graymiddark);
}
.detail-green {
  background-color: var(--detail-color-lightgreen);
}
.detail-red {
  background-color: var(--detail-color-red);
}
.detail-lightred {
  background-color: var(--detail-color-lightred);
}

//////////////////// detail box ////////////////////

.detail-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  // column-gap: clamp(20px, 3.5rem, 60px); // clamp doesn't seem to work
  column-gap: 3.5rem;
  row-gap: 3.5rem;
}

.detail-box-wide {
  grid-column: 1 / 3;
}
@media (max-width:714px){ // At 715px, the grid becomes 2 columns
  .detail-box-wide {
    grid-column: 1;
  }
}

</style>
<template>
  <div class="search-results">
    <div class="title">What are you looking for exactly?</div>

    <div class="result-grid">
      <router-link :to="`/quote/${r.id}`" v-for="r in searchResults" :key="r.id">
        <div class="result-box">
          <div class="result-symbol">{{ r.display_symbol }}</div>
          <div class="result-details">
            <div class="result-name">{{ r.name }}</div>
            <div class="result-currency">{{ r.country_currency }}</div>
            <div class="result-type">{{ r.type }}</div>
          </div>
        </div>
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: "SearchResults",
  props: ["searchTerm", "results"],
  components: {},
  data() {
    return {
      searchResults: []
    };
  },
  created() {
    // do some translations on the returned results
    for (let i = 0; i < this.results.length; i++) {
      let r = this.results[i];
      // shorten symbol length
      if (r.display_symbol.length > 10) {
        r.display_symbol =
          r.display_symbol.substring(0, 8) + "...";
      }
      // fix weird symbols in the name
      r.name = r.name.replaceAll('ﾙ', ' ').trim()
      // shorten name length
      if (r.name.length > 40) {
        r.name = r.name.substring(0, 38) + "...";
      }
      // figure out country-currency text
      if (r.country && r.currency) {
        r.country_currency = r.country + ",  " + r.currency;
      } else if (r.country) {
        r.country_currency = r.country;
      } else if (r.currency) {
        r.country_currency = r.currency;
      } else {
        r.country_currency = "";
      }
      this.searchResults.push(r)
    }
  },
};
</script>

<style scoped lang="scss">
.search-results {
  width: 100%;
  max-width: var(--content-width);
  margin-left: var(--content-min-horz-margin);
  margin-right: var(--content-min-horz-margin);
}

.title {
  margin-bottom: 30px;
  font-size: 1.8rem;
}

.result-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 19rem);
  column-gap: clamp(5px, 2rem, 20px);
  row-gap: clamp(5px, 2rem, 20px);
  justify-items: center; // align items in the center of their cell (horz)
  justify-content: center;
}

.result-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between; // so the content spreads out
  width: 19rem;
  height: 18rem;
  padding: 1rem 1rem;
  margin: 0 0;
  border-radius: 15px;
  -moz-border-radius: 15px;
  box-shadow: 0 3px 8px -5px var(--quotebox-shadow);

  font-size: 1.6rem;
}

.result-symbol {
  margin: 2.5rem auto 0 auto;
  font-size: 2rem;
  font-weight: 700;
}

.result-details {}
.result-name {
  margin-left: 0.7rem;
  margin-bottom: 0.3rem;
  font-size: 1.4rem;
  color: black;
}
.result-currency {
  margin-left: 0.7rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: var(--font-color-graymid);
}
.result-type {
  display: inline-block;
  padding: 0.4rem 0.7rem;
  background-color: var(--layout-color-almostwhite);
  border-radius: 15px;
  -moz-border-radius: 15px;

  font-size: 1.2rem;
  color: var(--font-color-graymid);
}
</style>
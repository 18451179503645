<template>
  <div>
      <div class="error-msg" v-if="showError"> Sorry, we couldn't get the news. You can try refreshing 🤷‍♂️</div>
  
      <div class="news-list">
          <div class="news-item" v-for="n in newsItems" :key="n.title">
            <div class="news-item-image" v-if="n.image_url"><img class="img-news" :src="n.image_url" /></div>
            <div class="news-item-content-container">
              <div class="news-item-content">
                <div class="news-item-content-title"><a v-bind:href="n.article_url" target="_blank" rel="noopener noreferrer">{{ n.title }}</a></div>
                <div class="news-item-content-body">
                  <div class="news-item-content-desc">{{ n.description }}</div>
                  <div class="news-item-content-details-row">
                    <div class="news-item-content-details">
                      <div class="news-item-content-date">{{ n.date_string }} ago</div>
                      <div class="news-item-content-source">{{ n.source }}</div>
                    </div>
                    <div class="news-item-content-details-type" v-if="n.type != 0">
                      <div class="source-type-1 source-type-box" v-show="n.type == 1">Paywall</div>
                      <div class="source-type-2 source-type-box" v-show="n.type == 2">Metered Paywall</div>
                      <div class="source-type-3 source-type-box" v-show="n.type == 3">Require Sign In</div>
                      <div class="source-type-4 source-type-box" v-show="n.type == 4">Metered Sign In</div>
                      <div class="source-type-5 source-type-box" v-show="n.type == 5">Paywall ?</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

  </div>
</template>

<script>
// @ is an alias to /src
import config from "@/config.js";
import { fetchMixin } from "@/mixins/fetch.js";

export default {
  name: "News",
  components: {
  },
  mixins: [fetchMixin],
  props: ["source"],
  data() {
    return {
      newsItems: [],
      showError: false,
    };
  },
  methods: {
    async getNewsData() {
      // Make the request
      var newsPath = config.apiUrl + config.apiPathNews + "/" + this.source;
      let { data, statusCode } = await this.makeRequest(newsPath, false);
      if (!data || statusCode != 200 || data == null || data.length <= 0) {
        this.showError = true
        return;
      }

      for (let i = 0; i < data.length; i++) {
        // Currently we don't use the date
        // let theDate = new Date(data[i].date);
        // console.log(theDate.toString(), theDate.toDateString())
        if (data[i].source.toLowerCase() == "youtube" && data[i].image_url == "") {
          data[i].image_url = "https://cdn.mos.cms.futurecdn.net/8gzcr6RpGStvZFA2qRt4v6-970-80.jpg.webp"
        }
        // Remove any HTML from the description
        data[i].description = this.removeHTML(data[i].description)
        this.newsItems.push(data[i])
      }
    },
    removeHTML(text) {
      let doc = new DOMParser().parseFromString(text, 'text/html'); // https://stackoverflow.com/a/47140708
      return doc.body.textContent || "";
    }
  },
  created() {
    // can make BE api calls here; have access to the reactive data properties
    // DOM has not been mounted yet, so you can (not?) manipulate that
    this.getNewsData()
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.error-msg {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
}
.news-list {
  display: flex;
  flex-direction: column;
  gap: 3em;
}
.news-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // flex-wrap: wrap;
  gap: 2.5em;
}
.img-news {
  width: 100%;
  height: auto;
}
.news-item-image {
  // max-width: 200px;
  flex-grow: 0;
  flex-shrink: 3;
  flex-basis: 200px;
}
.news-item-content-container {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 250px;
}
.news-item-content {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}
.news-item-content-title {
  font-size: 1.8rem;
  font-family: var(--font-roboto);
  font-weight: 700;
}
.news-item-content-title a {
  color: var(--font-color-black);
  cursor: pointer;
  background: transparent;
  text-decoration: none;
}
.news-item-content-title a:visited {
  color: var(--font-color-darkpurple);
}
.news-item-content-title a:hover {
  color: var(--font-color-turquoise);
}

.news-item-content-body {
  display: flex;
  flex-direction: column;
  gap: 0.6em;
}
.news-item-content-desc {
  font-family: var(--font-content);
  font-size: 1.4rem;
}
.news-item-content-details-row { // this has the details and type
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; // type will move to next line
  justify-content: space-between; // type will be aligned on the right
  align-items: baseline; //flex-end;
  gap: 0.5em;
}
.news-item-content-details { // this has the time and source
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1em;
}
.news-item-content-date {
  font-family: var(--font-roboto);
  font-size: 1.3rem;
  color: var(--font-color-graymiddark);
}
.news-item-content-source {
  font-family: var(--font-roboto);
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--font-color-graymid);
}

.news-item-content-details-type {
  margin-left: auto; // when the type wraps to next line, align it to the right
}
.source-type-box {
  font-size: 1.2rem;
  color: var(--font-color-graymid);
  padding: 0.4rem 0.9rem;
  border-radius: 15px;
  -moz-border-radius: 15px;
}
.source-type-1 {
  background-color: var(--detail-color-red);
  color: var(--font-color-graymiddark);
}
.source-type-2 {
  background-color: var(--detail-color-lightorange);
}
.source-type-3 {
  background-color: var(--detail-color-lightred);
}
.source-type-4 {
  background-color: var(--detail-color-yellow);
}
.source-type-5 {
  background-color: var(--detail-color-lightyellow);
}

// When the mini quotes go to 2 columns, move the quote selection as well
@media (max-width: 550px) {
  .news-item {
    gap: 1.5em;
  }
  .news-item-content-desc {
    display: none;
    visibility: hidden;
  }
  .news-item-content-title {
    font-size: 1.6rem;
  }
}
// When it gets even smaller, reduce font sizes
@media (max-width: 450px) {
  .news-item-content-title {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .news-item-content-source {
    font-size: 1.2rem;
  }
  .source-type-box {
    padding: 0.2rem 0.7rem;
  }
}
// Even smaller, reduce gap between image and text
@media (max-width: 400px) {
  .news-item {
  //  gap: 1.2em;
  }
}
</style>

<template>
  <div>
    <!-- with prevent the submit event will no longer reload the page -->
    <form 
      autocomplete="on"
      class="search-form"
      @submit.prevent="searchTicker"
    >
      <input 
        aria-label="Search"
        class="search-input"
        :class="[this.size == 'large' ? 'search-input-size-large' : 'search-input-size-small']"
        v-model="ticker"
        minlength="1"
        name="dq-search-input"
        :placeholder="this.placeholder"
        type="text"
        ref="searchinput"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      />

      <button class="search-button">
        <svg 
          viewbox="0 0 24 24"
          class="search-button-svg"
          :class="[this.size == 'large' ? 'search-button-svg-size-large' : 'search-button-svg-size-small']"
        >
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "SearchBox",
  props: {
    width: {
      default: 250,
      type: Number,
    },
    placeholder: {
      default: "ticker...",
      type: String,
    },
    size: {
      default: "large",
      type: String,
    },
  },
  data() {
    return {
      ticker: "",
    };
  },
  methods: {
    searchTicker() {
      // event parameter - we don't want form to submit to a file
      // having this seems to be same as @submit.prevent
      //e.preventDefault();

      this.ticker = this.ticker.trim();
      if (this.ticker == "") {
        // console.log("nothing to search");
        return;
      }
      // TODO popup error box if search term is invalid
      if (this.ticker.includes("/") || this.ticker.includes("\\")) {
        // || this.ticker.includes(" ")
        // console.log("invalid character");
        return;
      }
      // console.log("searching for " + this.ticker);

      // Do this method work?
      // var tik = this.ticker
      // this.$router.push({name: "QuoteView", params: { tik } })

      try {
        //this.$router.push({ path: `/quote/${this.ticker}` }); // works
        //let theTicker = this.ticker
        //this.$router.push({ name: 'QuoteView', params: { theTicker } }) // doesn't work
        this.$router.push({ name: 'QuoteView', params: { ticker:this.ticker } })
      } catch (error) {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }
      // Not sure if or why we need this below...
      /*.catch(err => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location'))
                {
                    // But print any other errors to the console
                    logError(err);
                }
            });*/

      // once we submit, clear the title
      this.ticker = "";
      // after search, don't focus the search input anymore
      this.$refs.searchinput.blur()
    },
  },
  mounted() {
    if (this.width != null) {
      let root = document.documentElement;
      root.style.setProperty("--search-width", this.width + "px");
    }
  },
};
</script>

<style scoped lang="scss">
// :root { // I don't think this is used; root is already defined in App.vue
//   --search-width: 250px;
// }

// An example of a custom mixin; see below on how to use it.
// @mixin border-radius-mixin($radius) {
//   -webkit-border-radius: $radius;
//      -moz-border-radius: $radius;
//       -ms-border-radius: $radius;
//           border-radius: $radius;
// }

.search-form {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: var(--search-width); // not sure if this does anything
}

.search-input {
  border: solid var(--searchbutton-color-lightgray);
  border-width: 1px 0 1px 1px;
  font-size: 1.6rem;
  margin: 0;
  text-indent: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.search-input-size-small {
  border-radius: 20px 0 0 20px;
  height: 35px;
  min-width: 90px;
  padding: 0px 0px 0px 0px; // if you want more padding around the text
}
.search-input-size-large {
  border-radius: 25px 0 0 25px;
  // @include border-radius-mixin(25px);
  height: 45px;
  min-width: 180px;
  padding: 0px 0px 0px 5px;
}

.search-input::placeholder {
  font-style: italic;
  font-size: 1.5rem;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic;
  font-size: 1.5rem;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  font-size: 1.5rem;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  font-size: 1.5rem;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-style: italic;
  font-size: 1.5rem;
}

.search-input:focus {
  outline: none;
  box-shadow: none;
}

.search-button {
  padding: 0;
  margin: 0; // iPhone adds some default spacing if we don't do this
  border-style: none;
  border-width: 0px;
  cursor: pointer;
  background-color: transparent;
  font-size: 0px; // without this, the button is larger then it needs to be
}

.search-button-svg {
  background-color: var(--font-color-blue);
  fill: var(--searchbutton-fill-color-white);
}
.search-button-svg-size-small {
  border-radius: 0 20px 20px 0;
  height: 35px;
  width: 37px;
  padding: 6px 10px 6px 5px; // before was 5, 7, 0, 5
}
.search-button-svg-size-large {
  border-radius: 0 25px 25px 0;
  height: 45px;
  width: 40px;
  padding: 12px 10px 6px 7px;
}

.search-button-svg:hover {
  background-color: var(--searchbutton-hover-color-gray);
  fill: var(--searchbutton-fill-color-white);
}
</style>
<template>
      <div>
        <div class="title">Earnings</div>

        <div class="info-content">

          <div class="info-item bd">
            <div class="item-header bd-red">Earnings Per Share (EPS)</div>
            <div class="item-description bd-red">
              Earnings per share indicates the dollar amount in earnings (or net income) the 
              company has made in the past four quarters (or 12 months), on a per share basis. 
              It is calculated as <span class="code-segment">annual net income / outstanding share count</span>.
              It is a measure of a company's profitability and the higher the number the better. 
              A negative value indicates the company has been losing money in the past 12 months.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Last Quarter</div>
            <div class="item-description bd-red">
              This is the date of the last quarter used in determining the displayed annual EPS value.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Price / Earnings (P/E) Ratio</div>
            <div class="item-description bd-red">
              The price/earnings (P/E) ratio is a valuation metric that compares a company's stock 
              price to the earnings the company generates. It tells you how much you're paying for 
              a dollar of earnings. It is calculated as 
              <span class="code-segment">share price / earnings per share</span>. Comparing 
              the P/E ratio across different companies is one method of determining if a company is 
              “cheap” or “expensive”. Typically the lower the number the better, as it indicates 
              you're paying a lower price for each dollar of earnings.
              <br /><br />
              If a company has 0 or negative earnings, the P/E ratio is not shown or shown as “Neg”. 
              Though a negative P/E ratio can be calculated, it's meaningless and shouldn't be considered.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Trailing Year-over-Year (YoY) Income Growth</div>
            <div class="item-description bd-red">
              Trailing year-over-year income growth indicates how much the company grew it's income 
              in the last two years. For example, if in Year 1 the company generated $100 of earnings and 
              in Year 2 had $110 of earnings, the result is a 10% year-over-year growth rate. Note, 
              this is a trailing 24 month calculation, so the past eight quarters of data is used.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Trailing 3 Year (3Yr) Income Growth</div>
            <div class="item-description bd-red">
              Trailing 3 year income growth is the compound annual growth rate (CAGR) of 
              income over the past three years. For example, if a company had $100 of income in the beginning 
              of Year 1 and generated $130 at the end of Year 3, that results in a CAGR of 9.14%. This company 
              grew it's earnings by 9.14% annually (compounded) over the last three years. Note, this is a 
              trailing 48 month calculation, so the past 16 quarters of data is used.
              <br /><br />
              When looking at income growth, the higher the number the better. Companies with a 
              high growth rate usually are more expensive, indicated by a higher P/E ratio. 
              Investors are willing to pay more if the company is expected to grow at a faster 
              rate than its peers and the market.
            </div>
          </div>

          <div class="info-note">
            The Sector and S&P 500 values shown are median values.
            <br />
            Values not shown or displayed as a "-" may be due to insufficient or missing data.
          </div>

        </div>

      </div>
</template>

<script>
export default {
  name: "EarningsInfo"
};
</script>

<style scoped lang="scss">
  @import "@/css/info-dialog.scss";
</style>

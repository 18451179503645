<template>
  <div class="search-container" ref="searchcontainer">
    <!-- with prevent the submit event will no longer reload the page @keydown.enter="onEnter" 
        :class="[this.size == 'large' ? 'search-input-size-large' : 'search-input-size-small']"
        :class="[showSearchAutocomplete ? 'search-input-size-large-res' : 'search-input-size-large-nores']"
    -->
    <form 
      autocomplete="off"
      class="search-form"
      @submit.prevent="searchTicker"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
    >
      <input 
        aria-label="Search"
        class="search-input"
        :class="{
          'search-input-size-large': this.size == 'large',
          'search-input-size-large-res': this.size == 'large' && showSearchAutocomplete,
          'search-input-size-large-nores': this.size == 'large' && !showSearchAutocomplete,
          'search-input-size-small': this.size == 'small',
        }"
        v-model="ticker"
        minlength="1"
        name="dq-search-input"
        :placeholder="this.placeholder"
        type="text"
        ref="searchinput"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        @input="updateText"
      />

      <!--
        :class="[this.size == 'large' ? 'search-button-svg-size-large' : 'search-button-svg-size-small']"
        :class="[showSearchAutocomplete ? 'search-button-svg-size-large-res' : 'search-button-svg-size-large-nores']"
      -->
      <button class="search-button">
        <svg 
          viewbox="0 0 24 24"
          class="search-button-svg"
          :class="{
            'search-button-svg-size-large': this.size == 'large',
            'search-button-svg-size-large-res': this.size == 'large' && showSearchAutocomplete,
            'search-button-svg-size-large-nores': this.size == 'large' && !showSearchAutocomplete,
            'search-button-svg-size-small': this.size == 'small',
          }"
        >
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>

    </form>

      <ul class="autocomplete-results" v-show="showSearchAutocomplete">
        <li class="autocomplete-result" 
            v-for="(result, i) in searchResults" :key="i" 
            @click="searchResultSelected(result.id)"
            :class="{ 'autocomplete-result-active': i == searchResultsArrowCounter }"
        >
          <div class="ac-result-symbol">{{ result.symbol }}</div>
          <div class="ac-result-right-group">
            <div class="ac-result-name">{{ result.name }}</div>
            <div class="ac-result-type-exchange">{{ result.type_exchange }}</div>
          </div>
        </li>
      </ul>

  </div>
</template>

<script>
import { fetchMixin } from "@/mixins/fetch.js";
import debounce from "lodash.debounce";
import config from "@/config.js";

export default {
  name: "SearchBoxAuto",
  mixins: [fetchMixin],
  props: {
    width: {
      default: 250,
      type: Number,
    },
    placeholder: {
      default: "ticker...",
      type: String,
    },
    size: {
      default: "large",
      type: String,
    },
  },
  data() {
    return {
      ticker: "",
      searchResults: [],
      showSearchAutocomplete: false,
      searchResultsArrowCounter: -1,
    };
  },
  methods: {
    updateText: debounce(function() {
            // https://lodash.com/docs/4.17.15#debounce
            // some info on usage: https://blog.codecourse.com/debounce-input-in-vue-3
            // debounce and throttling: https://css-tricks.com/debouncing-throttling-explained-examples/

            //console.log("searching:", this.ticker, e.target.value);
            //this.input = e.target.value;
            this.searchOnChange();
    }, 300, {'leading': true, 'trailing': true} ),
    async searchOnChange() {
      console.log("searching:", this.ticker);
      let ticker = this.ticker.trim().toLocaleLowerCase();
      if (ticker.length <= 0) {
        this.searchResults = [];
        this.showSearchAutocomplete = false;
        this.searchResultsArrowCounter = -1;
        //console.log(" setting to false:", this.searchResults.length, this.searchResults);
        return;
      }

      console.log(" making request");

      // Make the request
      // var apiPath = "http://ip:5000/symbols?s=" + ticker; // this is for the python server
      // var apiPath = "http://localhost:8080/auto-complete/" + ticker;
      var autoCompletePath = config.apiUrl + config.apiPathAutoComplete + "/" + ticker;
      let { data, statusCode } = await this.makeRequest(autoCompletePath, false);
      if (!data || statusCode != 200 || data == null || data.length <= 0) {
        this.searchResults = [];
        this.showSearchAutocomplete = false
        this.searchResultsArrowCounter = -1;
        console.log("no results found", statusCode);
        return;
      }

      this.searchResultsArrowCounter = -1;
      this.searchResults = data;
      this.showSearchAutocomplete = true;
    },
    searchResultSelected(resultCode) {
      console.log("searchResultSelected:", resultCode);

      // TODO do I need to do this?
      this.showSearchAutocomplete = false;
      this.searchResultsArrowCounter = -1;

      this.loadQuote(resultCode);
    },
    handleClickOutside(event) {
      console.log("handleClickOutside, target: ", event.target);
      console.log("                    this.el: ", this.$el);
      console.log("                    this.refs.searchcontainer: ", this.$refs.searchcontainer);

      if (!this.$refs.searchcontainer.contains(event.target)) {
        // if (!this.$el.contains(event.target)) {
        // Clicked outside of search container
        console.log("  inside if, not showing");
        this.showSearchAutocomplete = false;
        this.searchResultsArrowCounter = -1;
      } else {
        // Clicked inside of search container
        console.log("  inside else, showing");
        if (this.searchResults.length > 0) {
          this.showSearchAutocomplete = true;
        }
      }
    },
    onArrowDown() {
      console.log("onArrowDown", this.searchResultsArrowCounter, this.searchResults.length)
      if (this.searchResultsArrowCounter < this.searchResults.length-1) {
        this.searchResultsArrowCounter = this.searchResultsArrowCounter + 1;
        console.log("  inside if, new value:", this.searchResultsArrowCounter);
      }
    },
    onArrowUp() {
      console.log("onArrowUp", this.searchResultsArrowCounter)
      if (this.searchResultsArrowCounter >= 0) {
        this.searchResultsArrowCounter = this.searchResultsArrowCounter - 1;
        console.log("  inside if, new value:", this.searchResultsArrowCounter);
      }
    },
    onEnter() {
      console.log("onEnter", this.searchResultsArrowCounter)
      if (this.searchResultsArrowCounter < 0) {
        console.log("  inside if, no selection");
        return;
      }
      let selectedResult = this.searchResults[this.searchResultsArrowCounter];
      this.searchResultSelected(selectedResult.id);
    },
    searchTicker() {
      console.log("searchTicker - regular search on enter"); // TODO Remove this

      // TODO testing
      if (this.searchResultsArrowCounter >= 0) {
        let selectedResult = this.searchResults[this.searchResultsArrowCounter];
        this.searchResultSelected(selectedResult.id);
        return;
      }

      // event parameter - we don't want form to submit to a file
      // having this seems to be same as @submit.prevent
      //e.preventDefault();

      this.ticker = this.ticker.trim();
      if (this.ticker == "") {
        // console.log("nothing to search");
        return;
      }
      // TODO popup error box if search term is invalid
      if (this.ticker.includes("/") || this.ticker.includes("\\")) {
        // || this.ticker.includes(" ")
        // console.log("invalid character");
        return;
      }
      // console.log("searching for " + this.ticker);

      // Do this method work?
      // var tik = this.ticker
      // this.$router.push({name: "QuoteView", params: { tik } })

      try {
        //this.$router.push({ path: `/quote/${this.ticker}` }); // works
        //let theTicker = this.ticker
        //this.$router.push({ name: 'QuoteView', params: { theTicker } }) // doesn't work
        this.$router.push({ name: 'QuoteView', params: { ticker:this.ticker } })
      } catch (error) {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }
      // Not sure if or why we need this below...
      /*.catch(err => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location'))
                {
                    // But print any other errors to the console
                    logError(err);
                }
            });*/

      // once we submit, clear the title
      this.ticker = "";
      // after search, don't focus the search input anymore
      this.$refs.searchinput.blur()
    },
    loadQuote(tickerCode) {
      // Do this method work?
      // var tik = this.ticker
      // this.$router.push({name: "QuoteView", params: { tik } })

      try {
        //this.$router.push({ path: `/quote/${this.ticker}` }); // works
        //let theTicker = this.ticker
        //this.$router.push({ name: 'QuoteView', params: { theTicker } }) // doesn't work
        this.$router.push({ name: 'QuoteView', params: { ticker:tickerCode } })
      } catch (error) {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }
      // Not sure if or why we need this below...
      /*.catch(err => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location'))
                {
                    // But print any other errors to the console
                    logError(err);
                }
            });*/

      // once we submit, clear the title
      this.ticker = "";
      // after search, don't focus the search input anymore
      this.$refs.searchinput.blur()
    },
  },
  computed: {
    showSearchResults: function () {
      console.log("insude computed showSearchResults");
      return this.showSearchAutocomplete;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);

    if (this.width != null) {
      let root = document.documentElement;
      root.style.setProperty("--search-width", this.width + "px");
    }
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped lang="scss">
// :root { // I don't think this is used; root is already defined in App.vue
//   --search-width: 250px;
// }

// An example of a custom mixin; see below on how to use it.
// @mixin border-radius-mixin($radius) {
//   -webkit-border-radius: $radius;
//      -moz-border-radius: $radius;
//       -ms-border-radius: $radius;
//           border-radius: $radius;
// }


  .autocomplete-results {
    border: 1px solid #ccc;
    border-radius: 10px;
    -moz-border-radius: 10px;

    margin-top: -1px;
    margin-left: -65px;      // need this to shift the box over
    //padding: 5px 5px 5px 5px;
    padding: 5px 0 5px 0;
    //opacity: 0;
    //pointer-events: none;
    width: 350px;
    //max-height: 280px;
    overflow-y: auto;

    position:absolute;
    background-color: white;

    // padding: 0;
    // margin: 0;
    // border: 1px solid #eeeeee;
    // height: 120px;
    // min-height: 1em;
    // max-height: 6em;    
    // overflow: auto;
  }

// .search-container:focus-within .autocomplete-results {
//   //padding: 10px 8px;
//   opacity: 1;
//   pointer-events: auto;
// }

  .autocomplete-result {
    //border: 1px solid red;

    list-style: none;
    padding: 12px 10px 12px 15px;
    //display: none;
    //display: block;
    //width: 100%;
    //width: 250px;    // I had this before
    cursor: pointer;
    //border-radius: 3px;

    // list-style: none;
    // text-align: left;
    // padding: 4px 2px;
    // cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 0.5rem;

    font-family: var(--font-roboto);

    //border-bottom: 1px solid #cecece;
    //margin: 0 15px;
  }
   .autocomplete-result:hover {
     background-color: var(--layout-color-almostwhite);
   }
   .autocomplete-result-active {
     background-color: var(--layout-color-almostwhite);
   }

  .ac-result-symbol {
    //border: 1px solid blue;
    color: var(--font-color-graymiddark);
    font-size: 1.5rem;
    font-weight: 600;
    min-width: 65px;
  }

  .ac-result-right-group{
    //border: 1px solid purple;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //justify-content: flex-start;
    column-gap: 10px;
    width: 100%;
  }

  .ac-result-name {
    //border: 1px solid purple;
    //display: flex;
    //align-items: center;

    //color: var(--font-color-graymid1);
    color: #0079c6; // #599bc5;
    font-size: 1.3rem;
    font-weight: 400;
    width: 160px;
  }
  .ac-result-type-exchange {
    //border: 1px solid purple;
    display: flex;
    align-items: center;
    color: var(--font-color-graymid1);
    font-size: 1.1rem;
    font-weight: 400;
  }


////////////////////////
.search-form {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: var(--search-width); // not sure if this does anything
}

.search-input {
  border: solid var(--searchbutton-color-lightgray);
  border-width: 1px 0 1px 1px;
  font-size: 1.6rem;
  margin: 0;
  text-indent: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.search-input-size-small {
  border-radius: 20px 0 0 20px;
  height: 35px;
  min-width: 90px;
  padding: 0px 0px 0px 0px; // if you want more padding around the text
}
.search-input-size-large {
  // border-radius: 25px 0 0 25px;
  // @include border-radius-mixin(25px);
  height: 45px;
  min-width: 180px;
  padding: 0px 0px 0px 5px;
}
.search-input-size-large-nores {  // No auto complete results, round the corners
  border-radius: 25px 0 0 25px;
}
.search-input-size-large-res { // Auto complete results, round the top corners
  border-radius: 25px 0 0 0;
}

.search-input::placeholder {
  font-style: italic;
  font-size: 1.5rem;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic;
  font-size: 1.5rem;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  font-size: 1.5rem;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  font-size: 1.5rem;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-style: italic;
  font-size: 1.5rem;
}

.search-input:focus {
  outline: none;
  box-shadow: none;
}

.search-button {
  padding: 0;
  margin: 0; // iPhone adds some default spacing if we don't do this
  border-style: none;
  border-width: 0px;
  cursor: pointer;
  background-color: transparent;
  font-size: 0px; // without this, the button is larger then it needs to be
}

.search-button-svg {
  background-color: var(--font-color-blue);
  fill: var(--searchbutton-fill-color-white);
}
.search-button-svg-size-small {
  border-radius: 0 20px 20px 0;
  height: 35px;
  width: 37px;
  padding: 6px 10px 6px 5px; // before was 5, 7, 0, 5
}
.search-button-svg-size-large {
  // border-radius: 0 25px 25px 0;
  height: 45px;
  width: 40px;
  padding: 12px 10px 6px 7px;
}
.search-button-svg-size-large-nores { // No auto complete results, round the corners
  border-radius: 0 25px 25px 0;
}
.search-button-svg-size-large-res { // Auto complete results, don't round the corners
  border-radius: 0 25px 0 0;
}

.search-button-svg:hover {
  background-color: var(--searchbutton-hover-color-gray);
  fill: var(--searchbutton-fill-color-white);
}
</style>
<template>
  <div class="footer-box">
    <div class="nav">
      <!-- <router-link to="/" class="rlink">Home</router-link><span>|</span> -->
      <router-link to="/about" class="rlink">About</router-link><span>|</span>
      <router-link to="/contact" class="rlink">Contact</router-link><span>|</span>
      <router-link to="/disclaimer" class="rlink">Disclaimer</router-link>
    </div>
    <div class="copyright">© Stocknito 2023. All rights reserved.</div>
    <!-- <div class="disclaimer">
        We try our best to show accurate information on this site but there may be inaccuracies. Verify all information before making investment decisions. This site is for informational purposes only. Seek help from a professional if you don't know what you're doing.
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
.footer-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; // will wrap onto multiple lines
  justify-content: space-around; // where boxes justify to // center, flex-start, flex-end
  align-items: center; // where boxes are aligned on horizontally
  row-gap: 2rem;

  background-color: var(--layout-color-almostwhite);
  border-radius: 25px 25px 0 0;
  width: 100%;
  max-width: var(--content-width);
  margin: 0 auto;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

// flex items..
.nav {
  flex: 1 1 auto;
  text-align: center;
  margin: 0 2rem; // left/right space around entire nav

  font-size: 1.6rem;
  font-weight: 600;
  color: var(--font-color-pink);
}
.nav .rlink {
  color: var(--font-color-pink);
}
.nav .rlink:hover {
  text-decoration: underline;
}
.nav span {
  margin: 0 0.8rem;
}

.copyright {
  flex: 1 1 auto;
  text-align: center;
  margin: 0 2rem; // can remove this and add column gap if want

  font-size: 1.4rem;
  color: #575757;
}
</style>
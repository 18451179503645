<template>
  <div class="header-content" @click="navClicked">
    <input type="checkbox" id="hamburger" @click="hamburgerClicked" />
    <label for="hamburger"><span></span></label>

    <nav>
      <ul class="menus">
        <li tabindex="0" v-if="showHomeLink">
          <router-link to="/" class="rlink">Home</router-link>
        </li>
        <!-- for the tabindex: https://stackoverflow.com/questions/61652836/why-does-focus-within-not-work-in-safari -->
        <!-- another link that talks about this: https://stackoverflow.com/a/61770727 -->
        <!-- long read on keyboard-only focus: https://www.kizu.ru/keyboard-only-focus/#x -->
        <!-- remove sticky hover/menu: https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices -->
        <!-- remove sticky hover/menu: https://stackoverflow.com/questions/17233804/how-to-prevent-sticky-hover-effects-for-buttons-on-touch-devices -->
        <li tabindex="0">
          <button type="button">
            Investing<span class="submenu-arrow"></span>
          </button>
          <ul class="dropdown dropdown-1">
            <li>
              <router-link to="/investing/stock-investing-checklist" class="rlink">Stock Investing Checklist</router-link>
            </li>
          </ul>
        </li>
        <li tabindex="0">
          <router-link to="/about" class="rlink">About</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    showHomeLink: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  methods: {
    hamburgerClicked(event) {
      // If the hamburger menu is not visible, then return and do nothing
      const mediaQuery = window.matchMedia("(min-width: 800px)");
      if (mediaQuery.matches) {
        return;
      }

      if (event.target.checked) {
        // add listener to close nav when clicking outside of nav
        document.addEventListener("mousedown", this.checkClickedOutsideNav);
      } else {
        // remove the listener as the nav is closed
        document.removeEventListener("mousedown", this.checkClickedOutsideNav);
      }
    },
    navClicked(event) {
      // If the hamburger menu is not visible, then return and do nothing
      const mediaQuery = window.matchMedia("(min-width: 800px)");
      if (mediaQuery.matches) {
        return;
      }

      if (event.target.closest(".rlink")) {
        // clicked on a link, so close the nav
        this.closeNav(event);
      } else if (event.target.closest(".header-content")) {
        // clicked inside of nav, so do nothing
      } else {
        // will we ever get here? testing has shown that we don't
        // also it seems like checkClickedOutsideNav does the same thing
        this.closeNav(event);
      }
    },
    checkClickedOutsideNav(event) {
      // checkClickedOutsideNav
      // If the hamburger menu is not visible, then return and do nothing
      const mediaQuery = window.matchMedia("(min-width: 800px)");
      if (mediaQuery.matches) {
        return;
      }

      // check if event.target is child of nav
      // doesn't closet() check up the DOM tree, so above the element?
      if (event.target.closest(".header-content")) {
        // clicked inside of nav, so do nothing
      } else {
        // clicked outside of nav, so close the nav
        this.closeNav();
      }
    },
    closeNav() {
      let hamburgerCheckbox = document.getElementById("hamburger");
      hamburgerCheckbox.checked = false;
      // remove the listener as the nav is closed
      document.removeEventListener("mousedown", this.checkClickedOutsideNav);
    },
  },
};
</script>

<style scoped lang="scss">
// CSS combinators: https://www.freecodecamp.org/news/css-combinators-to-select-elements/

.header-content {
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  // color: #212529; // needed?
  // background: #8cc5ff; // debug
  // border: 1px solid #898d90; // debug
  order: 2; // this is to make the hamburger appear on the right side (?)
}

label {
  // create a box around the hamburger
  padding: 23px 10px; // changing last value from 20px to 10px fixed the alignment issue on safari
  cursor: pointer;
  right: 0; // position the hamburger to the top right
  top: 0;
  // border: 1px solid #898d90; // debug
}

input[type="checkbox"] {
  // don't show the checkbox
  opacity: 0;
  position: absolute;
  right: 0;
}

label span {
  // style the hamburger line
  width: 20px;
  height: 3px;
  display: block;
  background: var(--nav-color-hamburger);
  position: relative;
}

label span::after,
label span::before {
  // this is to get the 3 lines
  content: "";
  position: absolute;
  display: block;
  background: inherit;
  width: inherit;
  height: inherit;
}

label span::before {
  top: 8px;
}

label span::after {
  bottom: 8px;
}

label::before {
  // not sure what this is for
  position: absolute;
  content: "";
  width: 58px;
  height: 49px;
  top: 0;
  right: 0;
}

// Make hamburger have a box shadow when focused
input[type="checkbox"]:focus + label::before {
  // + is the adjacent sibling selector
  // box-shadow: 0 0 20px black;
}

ul {
  background: var(--nav-color-background); // set the background of the menu
  z-index: 99; // this will be over top everything, including a relative position div
}

ul li {
  list-style: none; // remove the bullet points
  font-size: 2.2rem;
  font-family: var(--font-nav-nunito);
  font-weight: 700;
  color: var(--nav-color-mobile-text);
}

ul li button {
  font-size: inherit;
  font-family: var(--font-nav-nunito);
  font-weight: 700;
  color: var(--nav-color-mobile-text);
  background-color: transparent;
  cursor: pointer;
  width: 100%; // make the button take up the whole width
  border: none;
}

ul li a,
ul li .rlink {
  display: block; // make the link take up the whole width
  color: inherit;
  text-decoration: none; // remove the underline
  // border: 1px solid red; // debug
}

ul li a,
ul li button,
ul li .rlink {
  padding: 0.7rem 2rem; // padding around the links and button
  text-align: left; // align the text to the left
}

.menus {
  position: absolute; // take out of the flow of the document
  top: 6rem; // shift down
  left: 0; // not sure what these do
  right: 0;
  padding-top: 3rem; // space above and below the nav text
  padding-bottom: 3rem;
  padding-inline-start: 20px; // property of ul with default of 40px
  visibility: hidden; // hide the menu
  transform: translateY(-2em); // while it's hidden, translate it up
  transition: transform 0.2s ease; // apply the transition to the transform; ease is the timing function
}

.dropdown {
  // this is the dropdown ul under a button
  padding: 2px 1.5rem; // space around the sub menu
  height: 0; // set the height of the sub menu to 0
  white-space: nowrap;
  overflow: hidden; // what happens when content is too big to fit in the container; hidden means overflow is hidden
  transition: height 0.2s ease; // apply the transition to the height
  // border: 1px solid blue; // debug
}

// make the submenu visible when the li is focused
// focusable - can tap it, click it or tab to it
// focus - whent the item gets focus
// focus-within - styling rules applied when any child element gets focus
li:focus-within .dropdown-x {
  // matches the dropdown ul that is a child of the li that is focused
  // https://stackoverflow.com/questions/3311299/css-transition-not-working-for-percentage-height
  // height: 100%;  // with 100% it doesn't transition smoothly
  height: 135px;
}

li:focus-within .dropdown-1 {
  height: 5rem;
}

li:focus-within .dropdown-2 {
  height: 10rem;
}

li:focus-within .dropdown-3 {
  height: 13.5rem;
}

// It's weird that we toggle the visibility of the ul instead of the menus (above)
input[type="checkbox"]:checked ~ nav > ul {
  // match ul element (child of nav) that are after the checkbox, which is checked
  visibility: visible; // toggle the dropdown when it's clicked
  transform: translateY(0); // translate it back down to it's original position
}

.submenu-arrow {
  // this is a span
  width: 0.5em;
  height: 0.5em;
  display: inline-block; // allows you to set width and height; inline elements can't have width and height
  vertical-align: middle;
  border-left: 0.15em solid currentColor;
  border-bottom: 0.15em solid currentColor;
  transform: rotate(-45deg);
  margin-left: 0.38em;
  margin-top: -0.25em;
  transition: transform 100ms ease-in-out;
  // border: 1px solid red; // debug
}

li:focus-within > button > .submenu-arrow {
  // matches the span that is a child of the button that is a child of the li that is focused
  transform: rotate(-225deg); // rotate the arrow when the li is clicked
  margin-top: 4px; // add some space above the arrow
}

@media (max-width: 340px) {
  // for smaller mobile screens
  .menus {
    padding-inline-start: 10px; // for larger mobile it's 20px
  }
}

// Navigation menu for desktop
@media (min-width: 800px) {
  .header-content {
    order: 0;
    display: flex;
    padding: 0;
    background: none;
  }

  .menus {
    position: static; // statis is default position
    visibility: visible;
    background: none;
    display: flex;
    transform: initial; // default value
    padding-top: 0; // space above and below the nav text
    padding-bottom: 0;
    padding-inline-start: 0;
  }

  label,
  input[type="checkbox"] {
    display: none; // hide the hamburger
  }

  ul {
    margin-top: 0; // so there's no space above and below the nav text
    margin-bottom: 0;
  }

  ul li {
    position: relative; // make the li position relative so that the dropdown can be positioned absolute (?)
    color: var(--nav-color-desktop-text);
    font-weight: 600;
    font-size: 1.6rem;
    font-family: var(--font-nav-opensans);
  }

  ul li button {
    color: var(--nav-color-desktop-text);
    font-weight: 600;
    font-family: var(--font-nav-opensans);
  }

  ul li a:hover,
  ul li button:hover,
  ul li .rlink:hover {
    color: var(
      --nav-color-desktop-hover-text
    ); // change the font color on mouse over
    // background-color: #f5f5f5; // #f2f2f2 change the background color when the mouse is over the link or button
  }

  .dropdown {
    position: absolute; // so the dropdown hovers over the content/body
    right: 0; // so the menu aligns with the right side of the button
    left: auto; // not sure what this does
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 99; // over top everything else
    min-width: 10rem; // set a min width on the side of the menu item
    padding: 0;
    background-color: var(
      --nav-color-desktop-dropdown-background
    ); // color of the submenu
    border-radius: 0 0 0.5rem 0.5rem; // rounded corners on the bottom

    height: 0; // TODO not sure about this.. didn't have this initially!
  }

  ul li:hover .dropdown-x {
    height: 135px; // the height of the submenu; is this used?
  }

  ul li:hover .dropdown-1 {
    height: 4rem;
  }

  ul li:hover .dropdown-2 {
    height: 8rem;
  }

  ul li:hover .dropdown-3 {
    height: 12rem;
  }

  // added - do these work?
  li:focus-within .dropdown-1 {
    height: 0;
  }

  li:focus-within .dropdown-2 {
    height: 0;
  }

  li:focus-within .dropdown-3 {
    height: 0;
  }

  ul li:hover > button > .submenu-arrow {
    transform: rotate(-225deg);
    margin-top: 4px;
  }

  // added
  li:focus-within > button > .submenu-arrow {
    // matches the span that is a child of the button that is a child of the li that is focused
    transform: rotate(-45deg); // rotate the arrow when the li is clicked
    margin-top: 0; // add some space above the arrow
  }
} // @media (min-width: 800px)
</style>
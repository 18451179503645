<template>
  <svg viewBox="0 0 40 40" v-bind:style="{ fill: fillColor }">
    <path
      d="M20,0C8.973,0,0,8.973,0,20c0,11.027,8.973,20,20,20c11.027,0,20-8.973,20-20C40,8.973,31.027,0,20,0z M28.018,19.752
                        c-0.451,0.531-1.094,0.805-1.738,0.805c-0.521,0-1.047-0.179-1.477-0.543l-2.521-2.141v9.186c0,1.26-1.021,2.28-2.281,2.28
                        c-1.26,0-2.281-1.021-2.281-2.28v-9.186l-2.521,2.141c-0.96,0.816-2.4,0.698-3.216-0.262c-0.816-0.961-0.698-2.4,0.262-3.216
                        l6.279-5.333c0.852-0.723,2.102-0.723,2.955,0l6.278,5.333C28.716,17.352,28.834,18.791,28.018,19.752z"
    />
  </svg>
</template>

<script>
// https://blog.logrocket.com/using-svg-and-vue-js-a-complete-guide/

export default {
  name: "SvgArrowUp",
  props: {
    fillColor: {
      type: String,
      default: "#161616", // --font-color-almostblack
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  display: block;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
}
</style>
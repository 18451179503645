<template>
      <div>
        <div class="title">Revenue</div>

        <div class="info-content">

          <div class="info-item bd">
            <div class="item-header bd-red">Price / Sales (P/S) Ratio</div>
            <div class="item-description bd-red">
              The price/sales (P/S) ratio is a valuation metric that compares a company's stock
              price to the annual revenue (or sales) the company generates. It tells you how much you're paying for
              a dollar of revenue. It is calculated as
              <span class="code-segment">share price / revenue per share</span>. Comparing
              the P/S ratio across different companies is one method of determining if a company is
              “cheap” or “expensive”. Typically the lower the number the better, as it indicates
              you're paying a lower price for each dollar of revenue.
              <br /><br />
              If a company is generating zero or negative income, such as a growth company, the P/S 
              ratio is good metric to fallback to as every company should be generating positive revenue.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Trailing Year-over-Year (YoY) Revenue Growth</div>
            <div class="item-description bd-red">
              Trailing year-over-year revenue growth indicates how much the company grew it's revenue 
              in the last two years. For example, if in Year 1 the company generated $100 of revenue and 
              then in Year 2 had $110 of revenue, the result is a 10% year-over-year growth rate. 
              Note, this is a trailing 24 month calculation, so the past eight quarters of data is used.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Trailing 3 Year (3Yr) Revenue Growth</div>
            <div class="item-description bd-red">
              Trailing 3 year revenue growth indicates the compound annual growth rate (CAGR) of 
              revenue over the past three years. For example, if a company had $100 of revenue 
              in the beginning of Year 1 and generated $130 at the end of Year 3, that results in a CAGR of 9.14%. 
              This company grew it's revenue by 9.14% annually (compounded) over the last three years. Note, this 
              is a trailing 48 month calculation, so the past 16 quarters of data is used.
              <br /><br />
              When looking at revenue growth, the higher the number the better. Companies with a 
              high growth rate usually are more expensive, indicated by a higher P/S or P/E ratio. 
              Investors are willing to pay more if the company is expected to grow at a faster rate 
              than the market.
            </div>
          </div>

          <div class="info-note">
            The Sector and S&P 500 values shown are median values.
            <br />
            Values not shown or displayed as a "-" may be due to insufficient or missing data.
          </div>

        </div>

      </div>
</template>

<script>
export default {
  name: "RevenueInfo"
};
</script>


<style scoped lang="scss">
  @import "@/css/info-dialog.scss";
</style>
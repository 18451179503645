<template>
      <div>
        <div class="title">Share Statistics</div>

        <div class="info-content">

          <div class="info-item bd">
            <div class="item-header bd-red">Volume</div>
            <div class="item-description bd-red">
              Volume represents the number of shares traded during a given period, usually a 
              single day when the market is open. If the market is closed, the volume of the 
              last active trading period is shown. A stock with low volume indicates fewer shares 
              are traded and low liquidity. Liquidity is how quickly a stock can be bought 
              or sold without affecting the stock price. The higher the liquidity the better.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">10 Day Average Volume</div>
            <div class="item-description bd-red">
              The 10 day average volume is the average volume over the last 10 trading days. 
              Average volume is a better indicator of liquidity than the current volume as any given 
              single day may have unusually high (or low) trading volume. 
              Also, comparing the current volume with the 10 day average can give you a sense of how active a 
              stock is on a given day.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Average $ Volume</div>
            <div class="item-description bd-red">
              Average dollar volume represents the average volume multiplied by the average share 
              price (over the last 10 days). It shows how active a stock is in dollar terms. 
              When determining how liquid a stock is, looking at the dollar volume may be more useful 
              than the volume alone. For example, Berkshire Hathaway Class A shares have an average 
              volume of about 8000 (as of this writing). This might seem like a low volume, illiquid 
              stock, but as each share is over $500K, in dollar volume this represents about $4 billion 
              in shares traded per day.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Short %</div>
            <div class="item-description bd-red">
              Short percent represents the percent of floating shares that have been sold short, 
              as of the prior month. A high short percent is typically not a good sign, as investors 
              may be betting on the share price to go down in the future. Some reasons for a high 
              short percent include the stock price being over valued, problems with the business 
              fundamentals or some other risk to future revenue and earnings.
            </div>
          </div>

          <div class="info-item bd">
            <div class="item-header bd-red">Share Count</div>
            <div class="item-description bd-red">
              Share count represents the year-over-year increase or decrease of total shares outstanding. 
              This value is calculated by taking the share count from the last quarter and comparing it 
              with the share count from the same quarter in the prior year. A decrease in share count 
              (a good thing) is typically due to a company buying back it's own shares and is a method 
              of returning profits to shareholders.
            </div>
          </div>

          <div class="info-note">
            The Sector values shown are median values.
            <br />
            Values not shown or displayed as a "-" may be due to insufficient or missing data.
          </div>

        </div>

      </div>
</template>

<script>
export default {
  name: "ShareStatisticsInfo"
};
</script>

<style scoped lang="scss">
  @import "@/css/info-dialog.scss";
</style>

export default {
    appVersion: require('../package.json').version,
    gtagMeasurementId: process.env.VUE_APP_GTAG_MEASUREMENT_ID,
    apiUrl: process.env.VUE_APP_API_URL,
    apiId: process.env.VUE_APP_API_ID,
    apiDky: process.env.VUE_APP_API_DKY,
    apiPathQuote: process.env.VUE_APP_API_PATH_QUOTE,
    apiPathRtQuote: process.env.VUE_APP_API_PATH_RT,
    apiPathHistQuote: process.env.VUE_APP_API_PATH_HIST,
    apiPathMiniQuote: process.env.VUE_APP_API_PATH_MINI,
    apiPathNews: process.env.VUE_APP_API_PATH_NEWS,
    apiPathContact: process.env.VUE_APP_API_PATH_CONTACT,
    apiPathAutoComplete: process.env.VUE_APP_API_PATH_AUTOCOMP
}
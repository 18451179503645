<template>
  <div class="quote-box bd" :class="[data.show_name ? 'quote-box-pad-small' : 'quote-box-pad-large']">
    <div class="symbol bd">{{ displaySymbol }}</div>
    <div class="name bd" v-if="data.show_name == true">{{ data.name }}</div>
    <div class="lower-row bd">
      <div class="price bd">{{ closeStr }}</div>
      <div class="change bd">
        <!-- Up arrow -->
        <SvgArrowUp v-if="upArrowEnabled" v-bind:fillColor="styleColorPercent" />
        <!-- Down arrow -->
        <SvgArrowDown v-if="downArrowEnabled" v-bind:fillColor="styleColorPercent" />
        <!-- Change percent -->
        <span
          class="change-value bd"
          v-bind:style="{ color: styleColorPercent }"
          >{{ changePercentStr }}%
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SvgArrowUp from "@/components/SvgArrowUp";
import SvgArrowDown from "@/components/SvgArrowDown";

// These are older money formatters.. remove later if not needed

// var moneyFormatter = new Intl.NumberFormat('en-US', {
//    style: 'currency',
//    currency: 'USD',
// These options are needed to round to whole numbers if that's what you want.
// minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
// maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
// });

// const formatter = new Intl.NumberFormat('en-US', {
//    minimumFractionDigits: 2,
//    maximumFractionDigits: 2,
// });

const numFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export default {
  name: "QuoteBox",
  props: ["data"],
  components: {
    SvgArrowUp,
    SvgArrowDown
  },
  data() {
    return {
      closeStr: "0",
      changePercentStr: "0",
      displaySymbol: "",
      upArrowEnabled: false,
      downArrowEnabled: false,
      styleColorPercent: "#161616", // --font-color-almostblack
      colorPercentGreen: "#04A93D", // --font-color-up-green
      colorPercentRed: "#EA393B", // --font-color-down-red
    };
  },
  created() {
    let changePercent = this.data.change_percent
    // In case the value is not a number, convert it
    if (typeof changePercent === "string") {
      changePercent = Number(changePercent);
    }

    // Get the rounded percent
    // We're trying round this to a decimal with 2 decimal places
    let percentRounded = 0.0
    if (changePercent < 0) {
      percentRounded = Math.round((changePercent - Number.EPSILON) * 100) / 100;
    } else {
      percentRounded = Math.round((changePercent + Number.EPSILON) * 100) / 100;
    }

    // Figure out which color and what arrow to show
    // We can't use changePercentStr from below because that's a string
    // and we can't compare
    if (percentRounded > 0) {
      this.styleColorPercent = this.colorPercentGreen;
      this.upArrowEnabled = true;
    } else if (percentRounded < 0) {
      this.styleColorPercent = this.colorPercentRed;
      this.downArrowEnabled = true;
    }

    // Don't have a "-" infront of percent value
    changePercent = Math.abs(changePercent);

    // Make 2 decimal places..?
    this.closeStr = this.data.close.toLocaleString("en", numFormatOptions);
    this.changePercentStr = changePercent.toLocaleString(
      "en",
      numFormatOptions
    );

    // For stocks and etfs use the symbol for the display symbol
    this.displaySymbol = this.data.name;
    if (this.data.type?.toLowerCase() == "common stock" ||
        this.data.type?.toLowerCase() == "etf") {
      this.displaySymbol = this.data.symbol
    }
  },
};
</script>

<style scoped lang="scss">
svg {
  display: block;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
}

.bd {
  // border: 1px solid rgb(168, 168, 168);
}

.quote-box {
  min-width: 16rem; // set the min width
  width: fit-content; // if it's larger then min, allow it grow
  border-radius: 15px;
  -moz-border-radius: 15px;
  box-shadow: 0 3px 8px -5px var(--quotebox-shadow); // https://css-tricks.com/almanac/properties/b/box-shadow/
  // box-shadow: 0 5px 10px -3px var(--quotebox-shadow);
  font-family: var(--font-roboto);
}
.quote-box-pad-large {
  padding: 20px 10px;
}
.quote-box-pad-small { // When we're showing the name
  padding: 12px 10px;
}

.name {
  margin-top: 3px;
  color: var(--font-color-graymid1);
  font-size: 1.1rem;
  font-weight: 400;
}

.symbol {
  color: var(--font-color-graymiddark);
  font-size: 1.5rem;
  font-weight: 600;
}

.lower-row {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 1.5rem;
  font-style: normal;
}

.price {
  color: var(--font-color-almostblack);
}

.change {
  display: flex;
  justify-content: center;
  margin-left: 0.5rem;
}

.change-value {
  margin-left: 0.3rem;
  font-weight: 600;
}
</style>
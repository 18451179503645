<template>
  <div class="dialog-overlay">

    <div class="dialog-box">
      <div class="dialog-content">
        <RevenueInfo v-if="infoType == 'Revenue'" />
        <EarningsInfo v-if="infoType == 'Earnings'" />
        <DividendsInfo v-if="infoType == 'Dividends'" />
        <ShareStatisticsInfo v-if="infoType == 'Share Statistics'" />
        <CompanyInfo v-if="infoType == 'Company'" />
      </div>

      <div>
        <!-- divider line -->
        <div class="header-border" />

        <div class="dialog-footer">
          <button @click="cancel" class="dialog-close-button">Close</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Code from here:
// https://www.youtube.com/watch?v=hmvxqXUS-BU
// https://github.com/thefaiz/vue-dialog/blob/master/src/App.vue

import EarningsInfo from "@/content/dialog/EarningsInfo.vue";
import RevenueInfo from "@/content/dialog/RevenueInfo.vue";
import DividendsInfo from "@/content/dialog/DividendsInfo.vue";
import ShareStatisticsInfo from "@/content/dialog/ShareStatisticsInfo.vue";
import CompanyInfo from "@/content/dialog/CompanyInfo.vue";

export default {
  name: "InfoDialog",
  props: ['cancel', 'infoType'],
  components: {
    EarningsInfo,
    RevenueInfo,
    DividendsInfo,
    ShareStatisticsInfo,
    CompanyInfo
  }
};
</script>


<style scoped lang="scss">

.dialog-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-box {
  background-color: rgba(255, 255, 255, 1.0);
  border-radius: var(--border-radius-quote-detail-box);
  -moz-border-radius: var(--border-radius-quote-detail-box);
  max-width: 750px;
  width: 90%;
  height:90%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dialog-content {
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  // Add scroll bar if needed in content section only (not entire dialog)
  overflow-y: auto;
  // overflow-y: scroll; // what does this do?
}

.header-border {
  border-bottom: 1px solid #969696;
  margin: 0 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}


.dialog-close-button {
  padding: 1rem 2.5rem;
  border-radius: 25px;
  border-width: 0px;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--font-color-white);
  background-color: var(--submit-button);
  cursor: pointer;
}

@media (max-width: 400px) {
  .dialog-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .dialog-footer {
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

</style>
export const utilsMixin = {
  data() {
    return {
    };
  },
  methods: {
    isMobile() {
      // console.log(window.innerWidth);
      // https://stackoverflow.com/questions/48515023/display-different-vuejs-components-for-mobile-browsers
      // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios

      let mobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      let isAppleDevice = navigator.userAgent.includes('Mac');
      // for iPad, as the user agent doesn't include "iPad" after iOS 13
      // Note, we won't use this for useIsMobile() because that's just for charts
      let isTouchScreen = navigator.maxTouchPoints >= 1;

      if (mobileUserAgent || (isAppleDevice && isTouchScreen)) {
        return true;
      } else {
        return false;
      }
    },
    // debounce(func, timeout) {
    //   let timeoutId;
    //   return (...args) => {
    //     clearTimeout(timeoutId);
    //     timeoutId = setTimeout(() => {
    //       func(...args);
    //     }, timeout);
    //   };
    // }
  }
}

//export default utilsMixin
<template>
  <svg viewBox="0 0 40 40" v-bind:style="{ fill: fillColor }">
    <path
      d="M20,0C8.973,0,0,8.973,0,20c0,11.027,8.973,20,20,20c11.027,0,20-8.973,20-20C40,8.973,31.027,0,20,0z M27.756,23.465
                        l-6.279,5.332C21.051,29.158,20.525,29.34,20,29.34c-0.526,0-1.052-0.182-1.478-0.543l-6.279-5.332
                        c-0.959-0.816-1.077-2.256-0.262-3.217c0.815-0.959,2.255-1.078,3.216-0.262l2.521,2.141v-9.186c0-1.26,1.021-2.281,2.282-2.281
                        c1.26,0,2.281,1.021,2.281,2.281v9.186l2.521-2.141c0.962-0.814,2.401-0.697,3.218,0.262
                        C28.834,21.209,28.716,22.648,27.756,23.465z"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgArrowDown",
  props: {
    fillColor: {
      type: String,
      default: "#161616", // --font-color-almostblack
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  display: block;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
}
</style>
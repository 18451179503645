<template>
  <div>
    <div class="header">
      <!-- logo -->
      <router-link to="/">
          <img
            class="header-logo"
            alt="Stocknito logo"
            src="../assets/stocknito_logo_300.png"
          />
      </router-link>

      <!-- navigation and search box -->
      <div class="nav-search">
        <!-- navigation -->
        <Nav />
        <!-- search box -->
        <SearchBox v-bind:width="150" v-bind:size="'small'" placeholder="" class="searchbox" />
      </div>
    </div>

    <!-- divider line -->
    <div class="header-border" />
  </div>
</template>

<script>
import SearchBox from "@/components/SearchBox.vue";
import Nav from "@/components/Nav.vue";

export default {
  name: "HeaderWithNav",
  components: {
    SearchBox,
    Nav,
  },
  methods: {},
};
</script>

<style scoped lang="scss">
// CSS combinators: https://stackoverflow.com/questions/10782054/what-does-the-tilde-squiggle-twiddle-css-selector-mean

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between; // how space in between is distributed along main axis
  // this was commented out before nav menu
  align-items: center; // vertically align items (along cross axis)
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  // border-bottom: 1px solid rgb(150, 150, 150);
  // border-bottom-width: thin;
}

.header-border {
  border-bottom: 1px solid #969696;
  margin: 0 15px;
  // border-bottom-width: thin;
  // width: 80%;
}

// sets property in child css
:deep(.search-button-svg) {
  background-color: var(--searchbutton-color-lightgray);
}

.nav-search {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  // border: 1px solid #898d90;
}

@media (max-width: 450px) {
  .nav-search {
    gap: 5px; // reduce the gap between the navigation and search box
  }
}


.header-logo {
  margin-top: 5px;
  margin-left: 5px;
  width: 150px;
}
@media (max-width: 450px) {
  .header-logo {
    margin-top: 0px;
    margin-left: 0px;
    width: 100px;
  }
}
</style>
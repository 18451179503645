import config from "@/config.js";
import axios from 'axios';

export const fetchMixin = {     // OR export default {
  data() {
    return {
      // tkn: Buffer.from(config.apiId + ":" + config.apiId, "utf8").toString(
      //   "base64"
      // ),
      // use window. so we don't get the depcrecated message
      // https://stackoverflow.com/questions/68849233/convert-a-string-to-base64-in-javascript-btoa-and-atob-are-deprecated
      tkn: window.btoa(config.apiId + ":" + config.apiId),
      decyKy: config.apiDky
    };
  },
  methods: {
    decry(text, key) {
      var result = '';
      for (var i = 0; i < text.length; i++) {
        result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return result;
    },
    //async makeRequest(reqURL, token, decryKy) {
    // async means it'll return a promise
    async makeRequest(reqURL, decodeResp=true) {
      try {
        // Make the get request
        // await only works inside async functions
        // await can be used on async promise functions, to pause code and wait until promise fulfills
        const resp = await axios.get(reqURL, {
          timeout: 10000,
          headers: {
            'Authorization': `Basic ${this.tkn}`
          }
        })
        // Get json data
        var jsonData = resp.data
        if (decodeResp && this.decyKy != "") {
          jsonData = JSON.parse(this.decry(jsonData, this.decyKy));
        }
        // Return the data, status code
        return { data: jsonData, statusCode: resp.status, error: "" };
      } catch (err) {
        // axios error handling: https://github.com/axios/axios#handling-errors
        if (err.response) {
          // The request was made and the server responded with a status code that is not 2xx
          var respStatusCode = err.response.status
          return { data: null, statusCode: respStatusCode, error: err.response.data };
          /* } else if (err.request) {
            // The request was made but no response was received, for example if the server doesn't respond
            // If we return err.request what are we supposed to do with that? Just use the one below
            return {data: null, statusCode: null, error: err.request}; */
        } else {
          // Something else happened
          return { data: null, statusCode: null, error: err.message };
        }
      }
    },
    async makePostRequest(reqURL, reqBody) {
      try {
        // Make the get request
        const resp = await axios.post(reqURL, reqBody, {
          timeout: 10000,
          headers: {
            'Authorization': `Basic ${this.tkn}`
          }
        })
        // Get json data
        var jsonData = resp.data
        if (jsonData && this.decyKy != "") { // here we check if jsonData is not null
          jsonData = JSON.parse(this.decry(jsonData, this.decyKy));
        }
        // Return the data, status code
        return { data: jsonData, statusCode: resp.status, error: "" };
      } catch (err) {
        // axios error handling: https://github.com/axios/axios#handling-errors
        if (err.response) {
          // The request was made and the server responded with a status code that is not 2xx
          var respStatusCode = err.response.status
          return { data: null, statusCode: respStatusCode, error: err.response.data };
          /* } else if (err.request) {
            // The request was made but no response was received, for example if the server doesn't respond
            // If we return err.request what are we supposed to do with that? Just use the one below
            return {data: null, statusCode: null, error: err.request}; */
        } else {
          // Something else happened
          return { data: null, statusCode: null, error: err.message };
        }
      }
    },
    makeRequestOrg(reqURL, token, decryKy) {
      console.log('org mixin making request to: ' + reqURL)

      // get returns a Promise?
      axios.get(reqURL, {
        timeout: 10000,
        headers: {
          'Authorization': `Basic ${token}`
        }
      }).then((res) => {
        //console.log(res);

        // Get json data
        var jsonData = res.data
        if (decryKy != "") {
          jsonData = JSON.parse(this.decy(jsonData, decryKy));
        }

        console.log("inside 1: ", jsonData)
        //return {data: jsonData, statusCode: 0, error: ""};

        return jsonData
      })
        .catch(err => {
          // axios error handling: https://github.com/axios/axios#handling-errors
          if (err.response) {
            // The request was made and the server responded with a status code that is not 2xx
            var respStatusCode = err.response.status
            console.log("inside 2")
            return { data: null, statusCode: respStatusCode, error: err.response.data };
          } else if (err.request) {
            // The request was made but no response was received, for example if the server doesn't respond
            //console.log(err.request)
            console.log("inside 3")
            return { data: null, statusCode: null, error: err.request };
          } else {
            // Something else happened
            //console.log('Error', err.message);
            console.log("inside 4")
            return { data: null, statusCode: null, error: err.message };
          }
        })
    }
  }
}

//export default fetchMixin